/* eslint-disable react/prop-types */
import React from "react";
import { Button as AntButton } from "antd";
import styled from "styled-components";

export const Button = ({ children, icon = null, ...props }) => {
	return (
		<StyledButton type="primary" shape="round" icon={icon} {...props}>
			{children}
		</StyledButton>
	);
};

const StyledButton = styled(AntButton)`
	&.ant-btn {
		font-family: DMSans-Medium;
		color: #222222;
		background-color: #ffffff;
		box-shadow: none;
		height: 44px;
		font-size: 12px;
		letter-spacing: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-inline-start: 16px;
		padding-inline-end: 16px;
        border-radius: 5px;
        border: 1px solid #265D71;
	}
	&.ant-btn:disabled {
		border: 1px solid #e9e8e8 !important;
		color: #d6d6d6 !important;
	}
	&.ant-btn-primary:not(:disabled) {
		border: 1px solid #265D71;
	}
	&.ant-btn-default:not(:disabled):hover,
	&.ant-btn-primary:not(:disabled):hover {
		color: #222222;
		background-color: #ffffff;
	}
	&.ant-btn-default:not(:disabled):hover {
		border-color: #e9e8e8;
	}
	&.ant-btn-lg {
		height: 50px;
		font-size: 14px;
		line-height: 22px;
		padding: 0 32px;
	}
	&.ant-btn-round.ant-btn-lg {
		border-radius: 5px;
		padding-inline-start: 32px;
		padding-inline-end: 32px;
	}
	&.ant-btn-sm {
		height: 34px;
		font-size: 10px;
		line-height: 18px;
	}
	&.ant-btn-primary .ant-btn-loading-icon {
		color: #265D71;
	}
`;
