/* eslint-disable react/prop-types */
import React from "react";

export const IconCameraSvg = ({ color = "#222222", ...props }) => {
	return (
		<svg width="24" height="20" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M7.93531 5.80842C7.93531 6.8756 7.06718 7.74373 6 7.74373C4.93282 7.74373 4.06502 6.8756 4.06502 5.80842C4.06502 4.74125 4.93282 3.87311 6 3.87311C7.06718 3.87311 7.93531 4.74158 7.93531 5.80842ZM12 3.06037V8.55714C12 9.28993 11.4059 9.88406 10.6731 9.88406H1.32692C0.594128 9.88406 0 9.28993 0 8.55714V3.06037C0 2.32758 0.594128 1.73345 1.32692 1.73345H2.95903V1.27434C2.95903 0.633102 3.47852 0.113281 4.12009 0.113281H7.87991C8.52148 0.113281 9.04097 0.633102 9.04097 1.27434V1.73312H10.6731C11.4059 1.73345 12 2.32758 12 3.06037ZM8.9305 5.80842C8.9305 4.19256 7.61586 2.87792 6 2.87792C4.38448 2.87792 3.06983 4.19256 3.06983 5.80842C3.06983 7.42428 4.38448 8.73892 6 8.73892C7.61586 8.73892 8.9305 7.42428 8.9305 5.80842Z"
				fill={color}
			/>
		</svg>
	);
};
