import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Col, Row, Grid } from "antd";

import { ModalBase } from "./ModalBase";
import { Button } from "../Button";

const { useBreakpoint } = Grid;

export const ModalConfirmation = ({ loading, open, onCancel, onOk, children, ...props }) => {
	const { md, xl } = useBreakpoint();

	const modalWidth = () => {
		if (xl) return 620;
		else {
			if (md) return 600;
			return "calc(100% - 64px)";
		}
	};

	return (
		<ModalBase
			open={open}
			onCancel={onCancel}
			width={modalWidth()}
			title="Xác nhận"
			footer={
				<Row align="middle" justify="center" gutter={32}>
					<Col xs={16} sm={12} md={10}>
						<Button size="large" type="default" block onClick={onCancel}>
							HỦY BỎ
						</Button>
					</Col>
					<Col xs={16} sm={12} md={10}>
						<Button size="large" loading={loading} block onClick={onOk}>
							XÁC NHẬN
						</Button>
					</Col>
				</Row>
			}
			{...props}
		>
			{children}
		</ModalBase>
	);
};

ModalConfirmation.propTypes = {
	open: PropTypes.bool,
	onCancel: PropTypes.func,
	children: PropTypes.any,
	onOk: PropTypes.func,
	loading: PropTypes.bool,
};

ModalConfirmation.defaultProps = {
	open: false,
	onCancel: noop,
	children: null,
	onOk: noop,
	loading: false,
};
