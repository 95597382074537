/* eslint-disable react/prop-types */
import React from "react";
import styled, { keyframes } from "styled-components";

export const CustomSkeleton = ({ width = "100%", height = 80 }) => {
	return <Container style={{ width, height }} />;
};

const skeletonAnim = keyframes`
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
`;

const Container = styled.div`
	border-radius: 8px;
	background: linear-gradient(
		90deg,
		rgba(34, 34, 34, 0.06) 25%,
		rgba(34, 34, 34, 0.15) 37%,
		rgba(34, 34, 34, 0.06) 63%
	);
	animation-name: ${skeletonAnim};
	background-size: 400% 100%;
	animation-duration: 1.4s;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
`;
