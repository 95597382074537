import * as React from "react"
export const IconIncrease = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#C1C1C1"
      stroke="#C1C1C1"
      strokeWidth={0.145}
      d="M11 2.2c-4.853 0-8.8 3.947-8.8 8.8 0 4.852 3.947 8.8 8.8 8.8 4.853 0 8.8-3.948 8.8-8.8S15.853 2.2 11 2.2Zm0 16.236c-4.1 0-7.437-3.335-7.437-7.436S6.9 3.563 11 3.563 18.437 6.899 18.437 11c0 4.1-3.336 7.436-7.437 7.436Z"
    />
    <path
      fill="#C1C1C1"
      stroke="#C1C1C1"
      strokeWidth={0.145}
      d="M14.408 10.257h-2.726V7.53a.681.681 0 1 0-1.364 0v2.727H7.592a.681.681 0 1 0 0 1.363h2.726v2.727a.681.681 0 1 0 1.364 0V11.62h2.726a.681.681 0 1 0 0-1.363Z"
    />
  </svg>
)
