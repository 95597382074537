/* eslint-disable react/prop-types */
import React from "react";
import { Avatar as AntAvatar } from "antd";
import styled from "styled-components";

import { getAvatarShortenedName } from "utils/helpers";

export const Avatar = ({ name = "", size = 40, ...props }) => (
	<StyledAvatar
		icon={<AvatarIcon style={{ fontSize: size / 2 - 6 }}>{getAvatarShortenedName(name)}</AvatarIcon>}
		size={size}
		{...props}
	/>
);

const StyledAvatar = styled(AntAvatar)`
	border-color: #ffffff;
`;

const AvatarIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #212731;
	color: #265D71;
	width: 100%;
	height: 100%;
	font-family: DMSans-Bold;
`;
