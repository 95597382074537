/* eslint-disable react/prop-types */
import React from "react";

export const IconPhoneSvg = ({ color = "#cdd1d8", ...props }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M17.5599 13.7434L15.3271 11.5105C14.5296 10.7131 13.174 11.0321 12.855 12.0687C12.6158 12.7865 11.8183 13.1852 11.1006 13.0257C9.50573 12.6269 7.35263 10.5536 6.9539 8.87894C6.71467 8.16121 7.19314 7.36377 7.91084 7.12456C8.94752 6.80559 9.2665 5.44993 8.46905 4.65248L6.2362 2.41964C5.59825 1.86142 4.64131 1.86142 4.0831 2.41964L2.56795 3.93478C1.05281 5.52967 2.72744 9.75613 6.47544 13.5041C10.2234 17.2521 14.4499 19.0065 16.0448 17.4116L17.5599 15.8965C18.1182 15.2585 18.1182 14.3016 17.5599 13.7434Z"
				fill={color}
			/>
		</svg>
	);
};
