/* eslint-disable react/prop-types */
import React from "react";

export const IconArrowDownSvg = ({ color = "#A8A8A8", ...props }) => {
	return (
		<svg width="12" height="8" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M4.99994 5.38597C5.15356 5.38597 5.30716 5.32731 5.42428 5.21025L9.10981 1.52468C9.34426 1.29023 9.34426 0.910118 9.10981 0.675765C8.87546 0.441412 8.49541 0.441412 8.26095 0.675765L4.99994 3.93696L1.73892 0.675879C1.50447 0.441526 1.12447 0.441526 0.890138 0.675879C0.655576 0.910232 0.655576 1.29035 0.890138 1.5248L4.57561 5.21036C4.69279 5.32744 4.84638 5.38597 4.99994 5.38597Z"
				fill={color}
			/>
		</svg>
	);
};
