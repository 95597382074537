import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { handleApi } from "configs/handleApi";
import { PAGE_SIZE_LIMIT, PAGE_SIZE_UN_LIMIT } from "utils/constants";

const actionGetListBooking = createAction("BOOKING_GET_LIST_BOOKING");
const actionGetBookingDetail = createAction("BOOKING_GET_DETAIL_BOOKING");
const actionGetCleaners = createAction("USER_GET_CLEANERS");
const actionGetBookingUpdate = createAction("BOOKING_GET_UPDATE_BOOKING");

export const apiGetListBooking = createAsyncThunk(actionGetListBooking.toString(), async (params = {}) => {
	const response = await handleApi({ url: "/private/bookings", params: { limit: PAGE_SIZE_LIMIT, ...params } });
	return response;
});

export const apiGetListBookingMinibarAndCleaning = createAsyncThunk(actionGetListBooking.toString(), async (params = {}) => {
	const response = await handleApi({ url: "/private/bookings/minibar_cleaning", params: { limit: PAGE_SIZE_LIMIT, ...params } });
	return response;
});

export const apiGetListBookingCleaning = createAsyncThunk(actionGetListBooking.toString(), async (params = {}) => {
	const response = await handleApi({ url: "/private/cleanings", params: { limit: PAGE_SIZE_LIMIT, ...params } });
	return response;
});

export const apiGetBookingDetail = createAsyncThunk(actionGetBookingDetail.toString(), async (uid) => {
	const response = await handleApi({ url: `/private/bookings/${uid}` });
	return response;
});
export const apiGetBookingCleaningDetail = createAsyncThunk(actionGetBookingDetail.toString(), async (uid) => {
	const response = await handleApi({ url: `/private/cleanings/${uid}` });
	return response;
});

export const apiGetBookingDetailForUpdate = async (uid) => {
	const response = await handleApi({ url: `/private/bookings/${uid}` });
	return response;
};

export const apiUpdateBooking = createAsyncThunk(actionGetBookingUpdate.toString(), async ({ uid, data }) => {
	const response = await handleApi({ url: `/private/bookings/${uid}`, method: "PUT", data });
	return response;
});


export const apiReport = async (payload, fileType) => {
	const response = await handleApi({ url: `/report?file_type=${fileType}`, method: "POST", data: payload, responseType: "blob" });
	return response;
};

export const apiReportMinibar = async (payload) => {
	const response = await handleApi({ url: `/report/minibar`, params: { limit: PAGE_SIZE_LIMIT, ...payload }, responseType: "JSON" });
	return response;
};

export const apiCalculateBookingInvoice = async ({ data }) => {
	const response = await handleApi({
		url: `/private/bookings/calculate_price`,
		method: "POST",
		data,
	});
	return response;
};

export const apiCreateBooking = async ({ data }) => {
	const response = await handleApi({ url: `/private/bookings`, method: "POST", data });
	return response;
};

export const apiMarkToPaid = async ({ id, paid, apartment_ids }) => {
	const response = await handleApi({
		url: `/private/invoices/${id}/mark_as_paid`,
		method: "PUT",
		data: { paid, apartment_ids },
	});
	return response;
};

export const apiUpdateInvoiceBooking = async ({ uid, data }) => {
	const response = await handleApi({ url: `/private/invoices/${uid}`, method: "PUT", data });
	return response;
};

export const apiUpdateInvoicePayoutBookingByType = async ({ uid, type, data }) => {
	const response = await handleApi({ url: `/private/invoices/${uid}/payout/${type}`, method: "PUT", data });
	return response;
};

export const apiUpdateCleaning = async ({ uid, data }) => {
	const response = await handleApi({ url: `/private/cleanings/${uid}`, method: "PUT", data });
	return response;
};

export const apiGetCleaners = async (userType) => {
	const response = await handleApi({ url: `/private/users`, params: { limit: PAGE_SIZE_UN_LIMIT, user_type: userType } });
	return response;
};