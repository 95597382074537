/* eslint-disable react/prop-types */
import React from "react";

export const IconCloseSvg = ({ color = "#A8A8A8", ...props }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.24141 14.5957C3.92017 14.917 3.92022 15.4378 4.24151 15.7591C4.56281 16.0803 5.08368 16.0803 5.40492 15.759L9.99966 11.1635L14.5948 15.7586C14.916 16.0798 15.4369 16.0798 15.7582 15.7586C16.0794 15.4373 16.0794 14.9164 15.7582 14.5952L11.163 9.99999L15.7578 5.40431C16.079 5.08302 16.079 4.56214 15.7577 4.2409C15.4364 3.91966 14.9155 3.91971 14.5943 4.241L9.99949 8.83659L5.40436 4.2414C5.0831 3.92013 4.56222 3.92013 4.24095 4.2414C3.91968 4.56267 3.91968 5.08354 4.24095 5.40482L8.83625 10.0001L4.24141 14.5957Z"
				fill={color}
			/>
		</svg>
	);
};
