import React from "react";
import styled from "styled-components";
import { AutoComplete as AntdAutoComplete, Input } from "antd";
import { Typography } from "../Typography";

export const AutoComplete = ({
	value,
	onSelect,
	handleSearch,
	prefix,
	suffix,
	width = "100%",
	height = "44px",
	placeholder = "Tìm kiếm ...",
	title,
	error,
	options = [],
	allowClear = true,
	onClear,
	disabled,
}) => {
	return (
		<WrapperInput disabled={disabled}>
			{title && (
				<WrapperTitle>
					<Typography color="#595959" variant="sub4">
						{title}
					</Typography>
				</WrapperTitle>
			)}
			<AntdAutoComplete
				dropdownMatchSelectWidth={252}
				style={{ width: width }}
				options={options}
				onSelect={onSelect}
				disabled={disabled}
				onSearch={handleSearch}
				value={value}
				allowClear={allowClear}
				onClear={onClear}
			>
				<Input placeholder={placeholder} prefix={prefix} suffix={suffix} style={{ width: width, height: height }} />
			</AntdAutoComplete>
			{!!error && (
				<Typography variant="body3" color="#BF1A2F" top="4px">
					{error}
				</Typography>
			)}
		</WrapperInput>
	);
};

const WrapperInput = styled.div`
	.ant-input,
	.ant-input-status-success {
		background: transparent;
		outline: 0;
	}
	.ant-input {
		background-color: #fff;
		border-radius: 12px;
		&:hover {
			outline: none;
			border-color: #265D71;
		}
		&:focus {
			outline: none;
			border-color: #265D71;
			box-shadow: 0px 0px 2px #265D71;
		}
	}
	.ant-input-affix-wrapper {
		&:hover {
			outline: none;
			border-color: #265D71;
		}
		&:focus {
			outline: none;
			border-color: #265D71;
			box-shadow: 0px 0px 2px #265D71;
		}
	}
	.ant-input-number-affix-wrapper {
		&:hover {
			outline: none;
			border-color: #265D71;
		}
		&:focus {
			outline: none;
			border-color: #265D71;
			box-shadow: 0px 0px 2px #265D71;
		}
	}
	.ant-input-number-affix-wrapper > div.ant-input-number {
		height: 100%;
		display: flex;
		align-items: center;
	}
	${({ disabled }) =>
		disabled &&
		`
        .ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper[disabled] {
            border: none;
            background-color: #F9F9F9;
        }
    `}
`;
const WrapperTitle = styled.div`
	padding-bottom: 8px;
`;
