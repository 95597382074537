import React from "react";
import styled from "styled-components";

export const CustomImage = ({ ...props }) => {
	return <StyledImage {...props} />;
};

const StyledImage = styled.img`
	border-radius: 8px;
	object-fit: cover;
`;
