import * as React from "react"
export const IconInfo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill="#F3F2F2" />
    <path
      fill="#265D71"
      fillRule="evenodd"
      d="M16 8c-4.416 0-8 3.584-8 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8Zm.8 12h-1.6v-4.8h1.6V20Zm-1.6-6.4h1.6V12h-1.6v1.6Z"
      clipRule="evenodd"
    />
  </svg>
)
