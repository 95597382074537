/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

import { IconCheckSvg } from "../Svgs";

export const Steps = ({ current = 0, steps }) => {
	const items = steps.map((item) => ({
		key: item.key,
		title: item.title,
	}));

	return (
		<div>
			<StepsLine>
				{items.map((item, index) => (
					<React.Fragment key={item.key}>
						<div>
							<StepItem>
								<div>
									{current >= index ? (
										<StepCircle>
											{current > index ? (
												<IconCheckSvg color="#ffffff" />
											) : (
												<StepIndex color="#FFFFFF">{index + 1}</StepIndex>
											)}
										</StepCircle>
									) : (
										<StepCircleInactive>
											<StepIndex color="#A8A8A8">{index + 1}</StepIndex>
										</StepCircleInactive>
									)}
								</div>
								<StepTitle color={index > current ? "#A8A8A8" : "#222222"}>{item.title}</StepTitle>
							</StepItem>
						</div>
						{index < items.length - 1 && <StepDivider color={index > current ? "#D6D6D6" : "#265D71"} />}
					</React.Fragment>
				))}
			</StepsLine>
			<Content>{steps[current]?.content}</Content>
		</div>
	);
};

const StepsLine = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StepItem = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const StepCircle = styled.div`
	width: 24px;
	height: 24px;
	background: #265D71;
	border-radius: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StepCircleInactive = styled.div`
	width: 24px;
	height: 24px;
	background: #ffffff;
	border-radius: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #a8a8a8;
`;

const StepDivider = styled.div`
	width: 100%;
	background: ${({ color }) => color};
	height: 2px;
	border-radius: 4px;
	margin: 0 12px;
`;

const StepTitle = styled.div`
	font-size: 14px;
	line-height: 22px;
	font-family: DMSans-Medium;
	white-space: nowrap;
	margin-left: 4px;
	color: ${({ color }) => color};
`;

const StepIndex = styled.div`
	font-family: DMSans-Medium;
	font-size: 14px;
	line-height: 18px;
	color: ${({ color }) => color};
`;

const Content = styled.div`
	margin-top: 20px;
`;
