import React from "react";
import { Input } from "antd";
import styled from "styled-components";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export const InputPassword = ({ ...props }) => {
	return (
		<StyledInput
			iconRender={(visible) => (visible ? <AiFillEye color="#8E929A" /> : <AiFillEyeInvisible color="#8E929A" />)}
			{...props}
		/>
	);
};

const StyledInput = styled(Input.Password)`
	&.ant-input-password {
		color: #65686f;
		font-family: DMSans-Medium;
		padding: 0 12px;
		font-size: 14px;
	}
	&.ant-input-password .ant-input {
		height: 38px;
	}
	&.ant-input-password .ant-input::placeholder {
		color: #a8a8a8;
		font-family: DMSans-Medium;
		font-size: 14px;
	}
	&.ant-input-password .ant-input-sm {
		padding-right: 12px;
		height: 30px;
	}
	&.ant-input-password .ant-input-lg {
		padding-right: 12px;
		height: 50px;
	}
`;
