import { createDraftSafeSelector, createReducer } from "@reduxjs/toolkit";

const INITIAL_STATE = {};

export const commonReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addDefaultCase(() => {});
});

export const commonSelector = createDraftSafeSelector(
	(state) => state.commonReducer,
	(state) => state
);
