/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import { Table as AntdTable } from "antd";
import { useSearchParams } from "react-router-dom";
import { Typography } from "components/elements";
import { PAGINATION_DEFAULT_PAGE } from "utils/constants";

export const Table = ({
	loading,
	columns,
	data,
	pagination,
	pageSize,
	total,
	paginationProps,
	onChangePageNumber,
	...props
}) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const { page, sort_by, sort_direction, ...params } = Object.fromEntries(searchParams);

	const handleChangePaging = (p) => {
		const ps = { ...params, page: p };
		if (sort_by) ps.sort_by = sort_by;
		if (sort_direction) ps.sort_direction = sort_direction;
		setSearchParams(ps);
	};
	return (
		<>
			{loading ? (
				<div>loading</div>
			) : (
				<>
					<StyledTable
						rowKey={(record) => record._id}
						// sticky
						pagination={
							pagination
								? {
									position: ["bottomCenter"],
									pageSize,
									total,
									showSizeChanger: false,
									hideOnSinglePage: true,
									current: Number(page ?? PAGINATION_DEFAULT_PAGE),
									onChange: (p) => handleChangePaging(p),
									itemRender: (_, type, originalElement) => {
										if (type === "prev")
											return (
												<Typography variant="subtitle1" style={{ color: "#35383F", letterSpacing: "1.1px" }}>
													Trước
												</Typography>
											);
										if (type === "next")
											return (
												<Typography variant="subtitle1" style={{ color: "#35383F", letterSpacing: "1.1px" }}>
													Sau
												</Typography>
											);
										return originalElement;
									},
									...paginationProps,
								}
								: false
						}
						columns={columns}
						dataSource={data}
						isEmpty={data.length === 0}
						onChange={onChangePageNumber}
						scroll={{ x: "max-content" }}
						{...props}
					/>
				</>
			)}
		</>
	);
};

const StyledTable = styled(AntdTable)`
	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
		display: none;
	}
	.ant-table td {
      padding: 0 !important; // Use !important to ensure override
    }
	.ant-table td, .ant-table-thead > tr > th {
      padding: 0 !important; // Use !important to ensure override for both td and th
    }
	.ant-table-body {
		overflow-x: ${({ isEmpty }) => (isEmpty ? "hidden" : "auto")} !important;
	}
	.ant-table {
		border-radius: 4px;
	}
	.ant-table table {
		border-radius: 4px 4px 0 0;
	}

	.ant-table-header,
	.ant-table-tbody > tr.ant-table-row:hover > td,
	.ant-table-tbody > tr > td.ant-table-cell-row-hover {
		background: transparent;
	}
	.ant-table-container,
	.ant-table-container table > thead > tr:first-child th:first-child {
		border-top-left-radius: 4px !important;
	}
	.ant-table-container,
	.ant-table-container table > thead > tr:first-child th:last-child {
		border-top-right-radius: 4px;
	}
	.ant-table-thead > tr > th {
		background: #f6f9fc;
		padding: 15px 20px;
		border: 0;
	}
	.ant-table-tbody > tr > td {
		padding: 25px 20px;
		border-bottom: 1px solid rgba(14, 14, 18, 0.08);
	}
	.ant-table-tbody > tr:nth-last-child(1) > td {
		border-bottom: 0;
	}
	.ant-table-pagination.ant-pagination {
		margin: 40px 0;
	}
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	.ant-pagination-item {
		margin-right: 10px;
	}
	.ant-pagination-prev {
		margin-right: 20px;
	}
	.ant-pagination-next {
		margin-left: 10px;
	}
	.ant-pagination-disabled p {
		color: #8e929a !important;
	}
	.ant-pagination-item a {
		color: #65686f !important;
		font-family: DMSans-Medium;
		font-size: 14px;
		line-height: 17px;
	}
	.ant-pagination-item-active a {
		color: #265D71 !important;
	}
	.ant-pagination-item-active {
		background: #ffffff !important;
	}
	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-next {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 36px;
		height: 36px;
		background: transparent;
	}
	.ant-pagination-item {
		border: 1px solid #dadadb;
		border-radius: 100px;
	}
	.ant-table-sticky-scroll {
		display: none;
	}
`;
