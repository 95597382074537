import * as React from "react"
export const IconCleaning = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#104177"
      d="M.281 4.753c.208.07.575.224.924.573.349.349.503.715.573.923a.41.41 0 0 0 .779 0c.07-.208.224-.574.573-.923.349-.349.715-.504.923-.573a.41.41 0 0 0 0-.78 2.364 2.364 0 0 1-.923-.572 2.363 2.363 0 0 1-.573-.923.41.41 0 0 0-.78 0c-.069.208-.223.574-.572.923a2.364 2.364 0 0 1-.924.573.41.41 0 0 0 0 .779ZM6.595 4.364c0 .226.183.41.41.41h.468v.469a.41.41 0 0 0 .82 0v-.469h.469a.41.41 0 0 0 0-.82h-.468v-.469a.41.41 0 0 0-.82 0v.469h-.47a.41.41 0 0 0-.41.41ZM4.808 1.699h.469v.468a.41.41 0 0 0 .82 0V1.7h.468a.41.41 0 0 0 0-.82h-.468V.41a.41.41 0 0 0-.82 0V.88h-.469a.41.41 0 0 0 0 .82ZM6.877 12.366c.014-.002.43-.056.818-.318l.043-.03.745-3.729h.279a.41.41 0 0 0 0-.82H.854a.41.41 0 0 0 0 .82h.279l.736 3.682a.685.685 0 0 0 .12-.095l2.53-2.53a.41.41 0 1 1 .58.58l-2.53 2.53c-.13.13-.314.258-.534.343l.174.872a.41.41 0 0 0 .402.33h3.233a1.233 1.233 0 0 1 1.033-1.635Z"
    />
    <path
      fill="#104177"
      d="M1.246 12.023a.68.68 0 0 1-.376-.867l-.206-1.031-.229.228c-.277.278-.43.647-.43 1.04 0 .61.364 1.155.927 1.388.41.17.792.137 1.103.017l-.166-.827a.68.68 0 0 1-.623.052ZM11.808 7.555V.41a.41.41 0 1 0-.82 0v7.145a2.997 2.997 0 0 1 .82 0ZM11.398 8.347a2.17 2.17 0 0 0-2.167 2.168c0 2.414-2.21 2.66-2.267 2.667a.41.41 0 0 0 .04.818h3.076c3.089 0 3.485-2.325 3.485-3.485a2.17 2.17 0 0 0-2.167-2.168Z"
    />
  </svg>
)
