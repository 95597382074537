/* eslint-disable react/prop-types */
import React from "react";
import { Spin } from "antd";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

export const LoadingElement = ({ size = 50 }) => {
	return (
		<Container>
			<Spin indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 24px;
`;
