import React from "react";
import { Row } from "antd";
import styled from "styled-components";

import { IconErrorSvg, Typography } from "components/elements";

export const NotfoundPage = () => {
	return (
		<Container>
			<div>
				<Row align="middle" justify="center">
					<IconErrorSvg />
				</Row>
				<Typography color="#DD4558" variant="h3" top={24} bottom={12} align="center">
					PAGE NOT FOUND
				</Typography>
				<Typography align="center">Page not found or not available.</Typography>
			</div>
		</Container>
	);
};

const Container = styled.div`
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;
