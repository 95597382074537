// @ts-nocheck
/* eslint-disable react/prop-types */
import React from "react";
import { Select as AntSelect } from "antd";
import styled from "styled-components";

import { IconArrowDownSvg } from "../Svgs";
import { NoResults } from "../NoResults";

export const Selection = ({ showSearch = false, round = true, ...props }) => {
	return (
		<StyledSelect
			showSearch={showSearch}
			suffixIcon={<IconArrowDownSvg />}
			notFoundContent={<NoResults description="Oops! You don't have any data" />}
			dropdownRender={(originNode) => <DropdownRender>{originNode}</DropdownRender>}
			dropdownMatchSelectWidth={false}
            round={round}
			{...props}
		/>
	);
};

const StyledSelect = styled(AntSelect)`
	width: 100%;
	&.ant-select .ant-select-selector {
		font-family: DMSans-Regular;
		font-size: 14px !important;
		height: fit-content;
		min-height: ${({ round }) => (round ? "44px" : "50px")};
		padding: 0 20px !important;
		border-radius: ${({ round }) => (round ? "5px" : 0)};
		margin-top: ${({ round }) => (round ? "0px" : "-2px")};
	}
	.ant-select-selection-item {
		display: flex;
		align-items: center;
	}
	.ant-select-selection-search,
	.ant-select-selection-placeholder {
		display: flex;
		align-items: center;
		inset-inline-start: 20px !important;
		inset-inline-end: 40px !important;
		color: #a8a8a8;
	}
	.ant-select-arrow {
		inset-inline-end: 20px;
	}
	&.ant-select {
		min-height: 44px;
	}
	&.ant-select-sm {
		min-height: 32px;
	}
	&.ant-select-lg {
		min-height: 50px;
	}
	&.ant-select-lg .ant-select-selector {
		min-height: 50px !important;
	}
	&.ant-select-sm .ant-select-selector {
		min-height: 32px !important;
	}
`;

const DropdownRender = styled.div`
	.ant-select-item {
		height: 50px;
		padding: 0 20px;
		color: #65686f;
		font-family: DMSans-Regular;
		font-size: 14px;
		display: flex;
		align-items: center;
	}
`;
