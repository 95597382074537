import { createDraftSafeSelector, createReducer } from "@reduxjs/toolkit";

import { apiGetAdminInfo, apiPostSignIn } from "./apis";

const INITIAL_STATE = {
	admin: {},
};

export const authReducer = createReducer(INITIAL_STATE, (builder) => {
	builder
		.addCase(apiPostSignIn.fulfilled, (state, action) => {
			state.admin = action.payload;
		})
		.addCase(apiGetAdminInfo.fulfilled, (state, action) => {
			state.admin = action.payload;
		})
		.addDefaultCase(() => {});
});

export const authSelectors = createDraftSafeSelector(
	(state) => state.authReducer,
	(state) => state
);
