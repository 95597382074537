

export const ERROR_DATA_FAILED = "Data Failed";
export const ERROR_CLIENT_UNKNOWN_ERROR = "Client Unknown Error";
export const REFRESH_TOKEN_EXPIRED = "Your session has expired, please login again.";

export const MEDIA_XS_MAX = "575px";
export const MEDIA_SM_MIN = "576px";
export const MEDIA_SM_MAX = "767px";

export const MEDIA_MD_MIN = "768px";
export const MEDIA_MD_MAX = "991px";

export const MEDIA_LG_MIN = "992px";
export const MEDIA_LG_MAX = "1199px";

export const MEDIA_XL_MIN = "1200px";
export const MEDIA_XL_MAX = "1599px";

export const MEDIA_XXL_MIN = "1600px";

export const SORT_DIRECTION = {
	ascend: "asc",
	descend: "desc",
};

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const LANDING_PAGE_URL = "https://thesongcondotel.com";

export const UPLOAD_ICON_MAX_SIZE = 20480;

export const UPLOAD_IMAGE_MAX_SIZE = 10485760;

export const UPLOAD_ONE_KB = 1024;

export const UPLOAD_ONE_MB = 1048576;

export const PAGINATION_DEFAULT_PAGE = 1;
export const PAGE_SIZE_LIMIT = 10;
export const PAGE_SIZE_MAX_LIMIT = 50;
export const PAGE_SIZE_UN_LIMIT = 1000;

export const UNAUTHORIZED_CODE = 401;
export const STATUS_SUCCESS = 200;
export const FILE_EXTENSION = {
	pdf: "application/pdf",
	imagePNG: "image/png",
	imageJPG: "image/jpg",
	imageJPEG: "image/jpeg",
	imageSVG: "image/svg+xml",
};

export const FILE_ACCEPT = {
	pdf: ".pdf",
	svg: ".svg",
};

export const TOAST_MESSAGE_TYPE = {
	SUCCESS: "success",
	ERROR: "error",
	INFO: "info",
	WARNING: "warning",
};

export const AXIOS_RESPONSE_TYPE = {
	JSON: "json",
	BLOB: "blob",
	ARRAYBUFFER: "arraybuffer",
	TEXT: "text",
};

export const APARTMENT_STATUS = {
	CREATED: "CREATED",
	ACTIVE: "ACTIVE",
	PAUSED: "PAUSED",
	DELETED: "DELETED",
};

export const APARTMENT_STATUS_VALUES = {
	CREATED: "Mới tạo",
	ACTIVE: "Đang hoạt động",
	PAUSED: "Tạm ngưng",
	DELETED: "Dừng hoạt động",
};

export const USER_STATUS_VALUES = {
	OWNER: "Chủ hộ",
	ADMIN: "Quản trị viên",
	CUSTOMER: "Khách hàng",
	SALER: "Nhân viên",
	CLEANER: "Vệ sinh",
};
export const AMENITY_CATEGORY = {
	LIVING_ROOM: "LIVING_ROOM",
	KITCHEN: "KITCHEN",
	BATH_ROOM: "BATH_ROOM",
	BED_ROOM: "BED_ROOM",
	COMMON: "COMMON",
};

export const IMAGE_CATEGORY = {
	PROFILE: "PROFILE",
	KITCHEN: "KITCHEN",
	LIVING_ROOM: "LIVING_ROOM",
	BATH_ROOM: "BATH_ROOM",
	BED_ROOM: "BED_ROOM",
	COMMON: "COMMON",
	DOCUMENT: "DOCUMENT",
};

export const BOOKING_STATUS = {
	TODAY: "TODAY",
	CREATED: "CREATED", // right after create
	DEPOSITED: "DEPOSITED", // user mark as booking was paid
	CONFIRMED: "CONFIRMED", // admin confirm
	CHECKED_IN: "CHECKED_IN", // user checked in
	CHECKED_OUT: "CHECKED_OUT", // user checked in
	CANCELLED: "CANCELLED",
	CLEANING: "CLEANING", // when apartment cleaning
	COMPLETED: "COMPLETED", // confirm done every thing
	EXPIRED: "EXPIRED",
};

export const CLEANING_STATUS = {
	CREATED: "CREATED", // right after create
	CLEANING: "CLEANING", // when apartment cleaning
	COMPLETED: "COMPLETED" // confirm done every thing
};

export const BOOKING_STATUS_DISPLAY = {
	CREATED: "Chưa xác nhận",
	DEPOSITED: "Đã báo cọc",
	CONFIRMED: "Đã xác nhận",
	CHECKED_IN: "Sắp out",
	CHECKED_OUT: "Đã out",
	CANCELLED: "Đã huỷ",
	CLEANING: "Đang vệ sinh",
	COMPLETED: "Hoàn tất",
	EXPIRED: "Hết hạn",
};

export const BENEFIT_AREA = {
	BED_ROOM: "BED_ROOM",
	LIVING_ROOM: "LIVING_ROOM",
	COMMON: "COMMON",
	KITCHEN: "KITCHEN",
	BATH_ROOM: "BATH_ROOM",
};

export const BENEFIT_AREADISPLAY = {
	LIVING_ROOM: "Phòng khách",
	BED_ROOM: "Phòng ngủ",
	BATH_ROOM: "Phòng tắm",
	KITCHEN: "Nhà bếp",
	COMMON: "Thông thường",
};

export const TRANSPORTS_PROVIDER = [
	{
		label: "Nhà xe Huy Hoàng",
		value: "Nhà xe Huy Hoàng",
	},
	{
		label: "Nhà xe Toàn Thắng",
		value: "Nhà xe Toàn Thắng",
	},
	{
		label: "Nhà xe Hoa Mai",
		value: "Nhà xe Hoa Mai",
	},
	{
		label: "Nhà xe Vie",
		value: "Nhà xe Vie",
	},
	{
		label: "Nhà xe Avigo",
		value: "Nhà xe Avigo",
	},
];

export const APARTMENT_SQUARE_TYPES = [
	{ label: "Studio + Suite 1Pn (3 người)", value: "small" },
	{ label: "Premium 1Pn+(4 người)", value: "medium" },
	{ label: "Luxury 2Pn+(6 người)", value: "large" },
	{ label: "President 3Pn (8 người)", value: "ultra_large" },
];

export const APARTMENT_STANDARD_TYPES = [
	{ label: "Bình thường", value: "normal" },
	{ label: "Đẹp", value: "hot" },
	{ label: "Cao cấp", value: "luxury" },
];

export const APARTMENT_STANDARD_TYPES_LABEL = {
	normal: "Bình thường",
	hot: "Đẹp",
	luxury: "Cao cấp",
};
export const BOOKING_FOR_MINIBAR_STATUS = [
	{ label: "Sắp out", value: BOOKING_STATUS.CHECKED_IN }

	// { label: "Hôm nay", value: "TODAY" },
];

export const BOOKING_FOR_CLEANING_STATUS = [
	{ label: "Đang vệ sinh", value: BOOKING_STATUS.CLEANING },
	{ label: "Đã hoàn tất", value: BOOKING_STATUS.COMPLETED },
	// { label: "Hôm nay", value: "TODAY" },
];

export const APARTMENT_TYPES = [
	{ label: "Tất cả", value: "all" },
	{ label: "Studio & Suite 1Pn (3 người)", value: "small" },
	{ label: "Premium 1Pn+ (4 người)", value: "medium" },
	{ label: "Luxury 2Pn+ (6 người)", value: "large" },
	{ label: "President 3Pn (8 người)", value: "ultra_large" },
];

export const SQUARE_TYPES = {
	small: "Studio + Suite 1Pn (3 người)",
	medium: "Premium 1Pn+(4 người)",
	large: "Luxury 2Pn+(6 người)",
	ultra_large: "President 3Pn (8 người)",
};

export const SERVICE_TYPES_STATUS = {
	MINIBAR: "MINIBAR",
	SERVICE: "SERVICE",
};
export const SERVICE_TYPES_STATUSDISPLAY = {
	MINIBAR: "Minibar",
	SERVICE: "Dịch vụ khác",
};

export const BOOKING_STATUS_SELECTION = [
	{
		label: "Tất cả",
		value: "ALL",
	},
	{
		label: "Mới",
		value: "CREATED",
	},
	{
		label: "Đã cọc",
		value: "DEPOSITED",
	},
	{
		label: "Đã xác nhận",
		value: "CONFIRMED",
	},
	{
		label: "Đã check in",
		value: "CHECKED_IN",
	},
	{
		label: "Đã check out",
		value: "CHECKED_OUT",
	},
	{
		label: "Đã huỷ",
		value: "CANCELLED",
	},
];

export const APARTMENT_VIEWS = {
	SEA_VIEW: "Biển trực diện",
	INTERNAL_VIEW: "Công viên nội khu",
	LIGHT_HOUSE_VIEW: "Biển - Núi - Thành phố",
	// HO_MAY_VIEW: "Hải đăng-Hồ mây",
	// BUILDING_VIEW: "Toà nhà",
	// JESUS_STATUE_VIEW: "Tượng chúa",
	CITY_VIEW: "Toà nhà-Thành phố",
	MOUNTAIN_VIEW: "Sân bay - cảng biển",
};

export const APARTMENT_VIEWS_SELECTION = [
	{
		label: "Tuỳ chọn",
		value: "all",
	},
	{
		label: "Biển trực diện",
		value: "SEA_VIEW",
	},
	{
		label: "Công viên nội khu",
		value: "INTERNAL_VIEW",
	},
	{
		label: "Biển - Núi - Thành phố",
		value: "LIGHT_HOUSE_VIEW",
	},
	// {
	// 	label: "Hải đăng-Hồ mây",
	// 	value: "HO_MAY_VIEW",
	// },
	{
		label: "Toà nhà-Thành phố",
		value: "CITY_VIEW",
	},
	// {
	//   label: "Tượng chúa",
	//   value: "JESUS_STATUE_VIEW",
	// },
	// {
	//   label: "Thành phố",
	//   value: "CITY_VIEW",
	// },
	{
		label: "Sân bay - cảng biển",
		value: "MOUNTAIN_VIEW",
	},
];

export const APARTMENT_VIEWS_OPTIONS = [
	{
		label: "Tuỳ chọn",
		value: "all",
	},
	{
		label: "Biển trực diện",
		value: "SEA_VIEW",
	},
	{
		label: "Công viên nội khu",
		value: "INTERNAL_VIEW",
	},
	{
		label: "Biển - Núi - Thành phố",
		value: "LIGHT_HOUSE_VIEW",
	},
	// {
	// 	label: "Hải đăng-Hồ mây",
	// 	value: "HO_MAY_VIEW",
	// },
	{
		label: "Toà nhà-Thành phố",
		value: "CITY_VIEW",
	},
	// {
	//   label: "Tượng chúa",
	//   value: "JESUS_STATUE_VIEW",
	// },
	// {
	//   label: "Thành phố",
	//   value: "CITY_VIEW",
	// },
	{
		label: "Sân bay - cảng biển",
		value: "MOUNTAIN_VIEW",
	},
];
export const SERVICE_TYPE_OPTIONS = [
	{
		label: "Minibar",
		value: "MINIBAR",
	},
	{
		label: "Dịch vụ khác",
		value: "SERVICE",
	},
];

export const PERMISSIONS = {
	ADMIN: {
		REPORT: 1,
		APPARTMENT: 1,
		BOOKING: 1,
		CUSTOMER: 1,
		SALER: 1,
		UPDATE_ALL_SALER: 1,
		CLEANING: 1,
		CLEANINGS: 1,
		CONTACT: 1,
		SETTING: 1,
		DELETE_SALER: 1,
		UPDATE_MY_SALER: 1,
	},
	SALER: {
		// REPORT: 1,
		APPARTMENT: 1,
		BOOKING: 1,
		// CUSTOMER: 1,
		SALER: 1,
		CLEANING: 1,
		UPDATE_ALL_SALER: 0,
		UPDATE_MY_SALER: 1,
		DELETE_SALER: 1,
		// CONTACT: 1,
		// SETTING: 1,
	},
	CLEANER: {
		CLEANING: 1,
		CLEANINGS: 1,
	},
};

export const PERMISSIONS_SETTING = {
	ADMIN: {
		MANAGE_PRICE: 1,
		SERVICE: 1,
		BENEFIT: 1,
	},
	SALER: {
		MANAGE_PRICE: 0,
		SERVICE: 1,
		BENEFIT: 1,
	},
};

export const USER_TYPE = {
	ADMIN: 'ADMIN',
	OWNER: 'OWNER',
	CUSTOMER: 'CUSTOMER',
	SALER: 'SALER',
	PARTNER: 'PARTNER',
	CLEANER: 'CLEANER',
};

export const BOOKING_PLATFORM = {
	TSR: 'TSR',
	BOOKING: 'BOOKING',
	AIRBNB: 'AIRBNB',
	TRAVELOKA: 'TRAVELOKA',
};

export const BOOKING_PLATFORM_DISPLAY = {
	TSR: 'TSR',
	BOOKING: 'Booking.com',
	AIRBNB: 'Airbnb',
	TRAVELOKA: 'Traveloka',
};

export const NO_AVAILABLE_ADMIN = 'NA';

export const PAYMENT_METHOD = {
	CASH: 'CASH',
	BANK_TRANSFER: 'BANK_TRANSFER',
};

export const PAYMENT_METHOD_TITLE = {
	CASH: 'Tiền mặt',
	BANK_TRANSFER: 'Chuyển khoản',
};