/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import cn from "classnames";
import { noop } from "lodash";
import { MEDIA_SM_MAX } from "utils/constants";

export const Typography = ({
	children = "",
	variant = "label",
	align = null,
	color = null,
	top = null,
	left = null,
	right = null,
	bottom = null,
	onClick = noop,
	truncate = false,
	...props
}) => {
	let style = props.style || {};

	if (color) style.color = color;
	if (top) style.marginTop = top;
	if (left) style.marginLeft = left;
	if (right) style.marginRight = right;
	if (bottom) style.marginBottom = bottom;
	if (align) style.textAlign = align;
	if (truncate) style = { ...style, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" };

	return (
		<Text className={cn(props.className, { [`typography-${variant}`]: variant })} style={style} onClick={onClick}>
			{children}
		</Text>
	);
};

const Text = styled.p`
	padding: 0;
	margin: 0;
	&.typography-h1 {
		color: #1c1c28;
		font-family: DMSans-Bold;
		font-weight: 700;
		font-size: 56px;
		line-height: 80px;
	}
	&.typography-h2 {
		color: #1c1c28;
		font-family: DMSans-Bold;
		font-weight: 700;
		font-size: 36px;
		line-height: 50px;
	}
	&.typography-h3 {
		color: #1c1c28;
		font-family: DMSans-Bold;
		font-weight: 700;
		font-size: 24px;
		line-height: 34px;
	}
	&.typography-h4 {
		color: #1c1c28;
		font-family: DMSans-Bold;
		font-weight: 700;
		font-size: 18px;
		line-height: 28px;
	}
	&.typography-h5 {
		color: #1c1c28;
		font-family: DMSans-Bold;
		font-weight: 700;
		font-size: 14px;
		line-height: 18px;
	}
	&.typography-h6 {
		color: #1c1c28;
		font-family: DMSans-Bold;
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
	}
	&.typography-sub1 {
		color: #222222;
		font-family: DMSans-Medium;
		font-weight: 500;
		font-size: 24px;
		line-height: 32px;
	}
	&.typography-sub2 {
		color: #222222;
		font-family: DMSans-Medium;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
	}
	&.typography-sub3 {
		color: #1c1c28;
		font-family: DMSans-Medium;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		@media (max-width: ${MEDIA_SM_MAX}) {
			font-size: 14px;
		}
	}
	&.typography-sub4 {
		color: #1c1c28;
		font-family: DMSans-Medium;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
	}
	&.typography-sub5 {
		color: #1c1c28;
		font-family: DMSans-Medium;
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
	}
	&.typography-body1 {
		color: #1c1c28;
		font-family: DMSans-Regular;
		font-weight: 400;
		font-size: 20px;
		line-height: 28px;
	}
	&.typography-body2 {
		color: #1c1c28;
		font-family: DMSans-Regular;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}
	&.typography-body3 {
		color: #1c1c28;
		font-family: DMSans-Regular;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
	}
	&.typography-body4 {
		color: #1c1c28;
		font-family: DMSans-Regular;
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
	}
	&.typography-button1 {
		color: #1c1c28;
		font-family: DMSans-Medium;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 3px;
		text-transform: uppercase;
	}
	&.typography-button2 {
		color: #1c1c28;
		font-family: DMSans-Medium;
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
	&.typography-button3 {
		color: #1c1c28;
		font-family: DMSans-Medium;
		font-weight: 500;
		font-size: 10px;
		line-height: 18px;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
	&.typography-error {
		color: #e73232;
		font-family: DMSans-Medium;
		font-weight: 500;
		font-size: 12px;
	}
	&.typography-label {
		color: #595959;
		font-family: DMSans-Regular;
		font-weight: 400;
		font-size: 12px;
		line-height: 22px;
	}
	&.typography-label1 {
		color: #1c1c28;
		font-family: DMSans-Bold;
		font-weight: 700;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
	&.typography-label2 {
		color: #1c1c28;
		font-family: DMSans-Bold;
		font-weight: 700;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
	&.typography-label3 {
		color: #1c1c28;
		font-family: DMSans-Bold;
		font-weight: 700;
		font-size: 10px;
		line-height: 18px;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
	&.typography-label4 {
		color: #595959;
		font-family: DMSans-Bold;
		font-weight: 700;
		font-size: 10px;
		line-height: 18px;
		letter-spacing: 1px;
	}
	&.typography-label5 {
		color: #222222;
		font-family: DMSans-Regular;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;
		text-transform: capitalize;
	}
`;
