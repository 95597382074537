/* eslint-disable react/prop-types */
import React from "react";

export const IconCheckSvg = ({ color = "#cdd1d8", ...props }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M16.002 6.00049L7.75195 14.0005L4.00195 10.3641"
				stroke={color}
				strokeWidth="1.8625"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
