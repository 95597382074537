/* eslint-disable react/prop-types */
import React from "react";
import { Tabs as AntTabs } from "antd";
import styled from "styled-components";

export const Tabs = ({ defaultActiveKey = null, items, ...props }) => (
	<AntTabs
		defaultActiveKey={defaultActiveKey || items[0]?.key}
		renderTabBar={(props, DefaultTabBar) => (
			<RenderTabBar>
				<DefaultTabBar {...props} />
			</RenderTabBar>
		)}
		items={items}
		tabBarGutter={0}
		{...props}
	/>
);

const RenderTabBar = styled.div`
	.ant-tabs-tab {
		color: #a8a8a8;
		font-family: DMSans-Medium;
		font-size: 14px;
		line-height: 22px;
		padding: 12px 48px;
	}
	.ant-tabs-tab-active {
	}
	.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: #222222 !important;
	}
	.ant-tabs-tab:hover {
		color: #222222 !important;
	}
	.ant-tabs-ink-bar {
		border-radius: 20px;
	}
`;
