/* eslint-disable react/prop-types */
import React from "react";

export const IconDashboardSvg = ({ color = "#cdd1d8", ...props }) => {
	return (
		<svg height="20" fill={color} viewBox="0 0 32 32" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="m26.43 31.15h-20.86a4.59 4.59 0 0 1 -4.57-4.58v-15.48a4.58 4.58 0 0 1 2.4-4l10.41-5.69a4.57 4.57 0 0 1 4.37 0l10.44 5.66a4.58 4.58 0 0 1 2.4 4v15.51a4.59 4.59 0 0 1 -4.59 4.58zm-10.43-28.31a2.58 2.58 0 0 0 -1.23.31l-10.44 5.67a2.58 2.58 0 0 0 -1.33 2.27v15.48a2.58 2.58 0 0 0 2.58 2.58h20.85a2.58 2.58 0 0 0 2.57-2.58v-15.48a2.58 2.58 0 0 0 -1.35-2.27l-10.42-5.66a2.58 2.58 0 0 0 -1.23-.32z" />
			<path d="m16 26.76a6 6 0 0 1 -5.89-4.85 1 1 0 0 1 1-1.15 1 1 0 0 1 1 .83 4 4 0 0 0 7.83 0 1 1 0 0 1 1-.83 1 1 0 0 1 1 1.15 6 6 0 0 1 -5.94 4.85z" />
		</svg>
	);
};
