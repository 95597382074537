/* eslint-disable react/prop-types */
import React from "react";
import { Dropdown as AntDropdown } from "antd";
import styled from "styled-components";

export const Dropdown = ({ children, ...props }) => {
	return (
		<AntDropdown
			trigger={["click"]}
			placement="bottomRight"
			dropdownRender={(menus) => <DropdownRender>{menus}</DropdownRender>}
			{...props}
		>
			{children}
		</AntDropdown>
	);
};

const DropdownRender = styled.div`
	.ant-dropdown-menu .ant-dropdown-menu-item {
		padding: 12px 16px;
	}
`;
