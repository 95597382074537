/* eslint-disable react/prop-types */
import React from "react";

export const IconHamburgerMenuSvg = ({ color = "#A8A8A8", ...props }) => {
	return (
		<svg width="32" height="32" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M18.25 11.25H1.75C1.336 11.25 1 10.914 1 10.5C1 10.086 1.336 9.75 1.75 9.75H18.25C18.664 9.75 19 10.086 19 10.5C19 10.914 18.664 11.25 18.25 11.25Z"
				fill={color}
				stroke={color}
				strokeWidth="0.4"
			/>
			<path
				d="M18.25 5.5H1.75C1.336 5.5 1 5.164 1 4.75C1 4.336 1.336 4 1.75 4H18.25C18.664 4 19 4.336 19 4.75C19 5.164 18.664 5.5 18.25 5.5Z"
				fill={color}
				stroke={color}
				strokeWidth="0.4"
			/>
			<path
				d="M18.25 16.9998H1.75C1.336 16.9998 1 16.6638 1 16.2498C1 15.8358 1.336 15.4998 1.75 15.4998H18.25C18.664 15.4998 19 15.8358 19 16.2498C19 16.6638 18.664 16.9998 18.25 16.9998Z"
				fill={color}
				stroke={color}
				strokeWidth="0.4"
			/>
		</svg>
	);
};
