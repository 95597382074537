import React from "react";
import PropTypes from "prop-types";
import { Modal, Grid } from "antd";
import { noop } from "lodash";
import styled from "styled-components";

import { IconCloseSvg } from "../Svgs";
import { MEDIA_SM_MAX, MEDIA_XS_MAX } from "utils/constants";

const { useBreakpoint } = Grid;

export const ModalBase = ({ width = null, open, onCancel, children, ...props }) => {
	const { md, xl, xxl } = useBreakpoint();

	const modalWidth = () => {
		if (xxl) {
			return 992;
		} else {
			if (xl) return 800;
			else {
				if (md) return 768;
				return "100%";
			}
		}
	};

	return (
		<StyledModal
			width={width ?? modalWidth()}
			centered
			destroyOnClose
			open={open}
			onCancel={onCancel}
			closeIcon={<IconCloseSvg width={28} height={28} />}
			footer={null}
			{...props}
		>
			{children}
		</StyledModal>
	);
};

ModalBase.propTypes = {
	open: PropTypes.bool,
	onCancel: PropTypes.func,
	children: PropTypes.node,
	width: PropTypes.any,
};

ModalBase.defaultProps = {
	open: true,
	onCancel: noop,
	width: null,
};

const StyledModal = styled(Modal)`
	padding: 0 24px;
	@media (max-width: ${MEDIA_XS_MAX}) {
		padding: 0;
	}
	.ant-modal-content {
		background-color: #ffffff;
		border-radius: 24px;
		box-shadow: none;
		padding: 40px;
		@media (max-width: ${MEDIA_SM_MAX}) {
			padding: 48px;
		}
		@media (max-width: ${MEDIA_XS_MAX}) {
			padding: 32px;
		}
	}
	.ant-modal-close {
		width: 28px;
		height: 28px;
		top: 22px;
		right: 22px;
	}
	.ant-modal-header {
		margin-bottom: 24px;
        padding-left: 0px;
	}
	.ant-modal-title {
		font-size: 24px;
		line-height: 28px;
		color: #000000;
		padding-left: 12px;
		font-family: DMSans-Regular;
		border-left: 2px solid #265D71;
		font-weight: 400;
	}
	.ant-modal-body {
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 60vh;
        padding: 0px;
	}
	.ant-modal-footer {
		margin-top: 30px;
	}
`;
