import * as React from "react"
export const IconCheckout = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    {...props}
  >
    <path
      stroke="#FFB11A"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.863}
      d="M7.236 1.4V2.77m0 7.252v2.19m-3.42-5.474H1.898m10.4 0h-.82m-.708 3.535-.388-.387m.5-6.752-.773.774m-6.748 6.705L4.91 9.064M3.475 3.02 4.636 4.18"
    />
  </svg>
)
