/* eslint-disable react/prop-types */
import React, { isValidElement } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "antd";

import { Typography } from "../Typography";
import { IconArrowSvg } from "../Svgs";

export const BreadCrumb = ({ breads = [], extraElement = null }) => {
	const navigate = useNavigate();

	return (
		<>
			{isValidElement(extraElement) ? (
				<Row align="middle" justify="space-between">
					<Col>
						<Row align="middle">
							{breads.map((bread, index) => (
								<Row align="middle" key={bread.label}>
									{index < breads.length - 1 ? (
										<>
											<Row align="middle" style={{ cursor: "pointer" }}>
												<Typography variant="h3" color="#A8A8A8" onClick={() => navigate(bread.path)}>
													{bread.label}
												</Typography>
											</Row>
											<Row align="middle" style={{ margin: "0 16px" }}>
												<IconArrowSvg width={16} height={16} />
											</Row>
										</>
									) : (
										<Row align="middle" style={{ cursor: "pointer" }}>
											<Typography variant="h3">{bread.label}</Typography>
										</Row>
									)}
								</Row>
							))}
						</Row>
					</Col>
					<Col>{extraElement}</Col>
				</Row>
			) : (
				<Row align="middle">
					{breads.map((bread, index) => (
						<Row align="middle" key={bread.label}>
							{index < breads.length - 1 ? (
								<>
									<Row align="middle" style={{ cursor: "pointer" }}>
										<Typography variant="h3" color="#A8A8A8" onClick={() => navigate(bread.path)}>
											{bread.label}
										</Typography>
									</Row>
									<Row align="middle" style={{ margin: "0 16px" }}>
										<IconArrowSvg width={16} height={16} />
									</Row>
								</>
							) : (
								<Row align="middle" style={{ cursor: "pointer" }}>
									<Typography variant="h3">{bread.label}</Typography>
								</Row>
							)}
						</Row>
					))}
				</Row>
			)}
		</>
	);
};
