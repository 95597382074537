import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";

import { ErrorElement } from "pages/ErrorElement";
import { NotfoundPage } from "pages/notfound";
import { Loading } from "components/elements";

const MainLayout = React.lazy(() => import("components/layouts/MainLayout"));
const AuthLayout = React.lazy(() => import("components/layouts/AuthLayout"));
const ApartmentsPage = React.lazy(() => import("pages/apartments"));
const ApartmentDetailPage = React.lazy(() => import("pages/apartmentDetail"));
const BookingsPage = React.lazy(() => import("pages/bookings"));
const ReportPage = React.lazy(() => import("pages/report"));
const BookingDetailPage = React.lazy(() => import("pages/bookingDetail"));
const CustomersPage = React.lazy(() => import("pages/customers"));
const MinibarPage = React.lazy(() => import("pages/minibar"));
const CleaningsPage = React.lazy(() => import("pages/cleanings"));
const CleaningDetailPage = React.lazy(() => import("pages/minibarDetail"));
const CleaningsDetailPage = React.lazy(() => import("pages/cleaningsDetail"));
const CustomerContactsPage = React.lazy(() => import("pages/customerContacts"));
const CustomerDetailPage = React.lazy(() => import("pages/customerDetail"));
const SalerDetailPage = React.lazy(() => import("pages/salerDetail"));
const SettingsPage = React.lazy(() => import("pages/settings"));
const SalerPage = React.lazy(() => import("pages/saler"));
const SignInPage = React.lazy(() => import("pages/signIn"));
const SettingSystemPricesPage = React.lazy(() => import("pages/settings/systemPrices"));
const SettingServiesPage = React.lazy(() => import("pages/settings/services"));
const SettingBenefitsPage = React.lazy(() => import("pages/settings/benefits"));

export const PATH_NOTFOUND = "*";
export const PATH_BASE = "/";
export const PATH_APARTMENTS = "/apartments";
export const PATH_APARTMENT_DETAIL = "/apartments/:uid";
export const PATH_BOOKINGS = "/bookings";
export const PATH_REPORT = "/reports";
export const PATH_BOOKING_DETAIL = "/bookings/:uid";
export const PATH_CUSTOMERS = "/customers";
export const PATH_SALERS = "/salers";
export const PATH_CUSTOMER_CONTACT = "/customer-contacts";
export const PATH_MINIBAR = "/minibar";
export const PATH_MINIBAR_DETAIL = "/minibar/:uid";
export const PATH_CLEANINGS = "/cleanings";
export const PATH_CLEANINGS_DETAIL = "/cleanings/:uid";
export const PATH_CUSTOMER_DETAIL = "/customers/:uid";
export const PATH_SALER_DETAIL = "/salers/:uid";
export const PATH_SETTINGS = "/settings";
export const PATH_SETTINGS_SYSTEM_PRICES = "/settings/system-prices";
export const PATH_SETTINGS_SERVICES = "/settings/services";
export const PATH_SETTINGS_BENEFITS = "/settings/benefits";
export const PATH_SIGN_IN = "/sign-in";

export const MENU_ITEMS_KEY = [
	PATH_APARTMENTS,
	PATH_BOOKINGS,
	PATH_CUSTOMERS,
	PATH_CUSTOMER_CONTACT,
	PATH_SETTINGS,
	PATH_SALERS,
	PATH_REPORT,
	PATH_MINIBAR,
	PATH_CLEANINGS,
];

export const router = createBrowserRouter([
	{
		path: PATH_BASE,
		element: (
			<React.Suspense fallback={<Loading />}>
				<MainLayout />
			</React.Suspense>
		),
		errorElement: <ErrorElement />,
		children: [
			{
				index: true,
				element: <Navigate to={PATH_APARTMENTS} replace />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_APARTMENTS,
				element: <ApartmentsPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_APARTMENT_DETAIL,
				element: <ApartmentDetailPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_BOOKINGS,
				element: <BookingsPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_REPORT,
				element: <ReportPage />,
				errorElement: <ErrorElement />,
			},

			{
				path: PATH_BOOKING_DETAIL,
				element: <BookingDetailPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_CUSTOMERS,
				element: <CustomersPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_SALERS,
				element: <SalerPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_MINIBAR,
				element: <MinibarPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_CLEANINGS,
				element: <CleaningsPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_MINIBAR_DETAIL,
				element: <CleaningDetailPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_CLEANINGS_DETAIL,
				element: <CleaningsDetailPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_CUSTOMER_CONTACT,
				element: <CustomerContactsPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_CUSTOMER_DETAIL,
				element: <CustomerDetailPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_SALER_DETAIL,
				element: <SalerDetailPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_SETTINGS,
				element: <SettingsPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_SETTINGS_SYSTEM_PRICES,
				element: <SettingSystemPricesPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_SETTINGS_SERVICES,
				element: <SettingServiesPage />,
				errorElement: <ErrorElement />,
			},
			{
				path: PATH_SETTINGS_BENEFITS,
				element: <SettingBenefitsPage />,
				errorElement: <ErrorElement />,
			},
		],
	},
	{
		path: PATH_SIGN_IN,
		element: (
			<React.Suspense fallback={<Loading />}>
				<AuthLayout>
					<SignInPage />
				</AuthLayout>
			</React.Suspense>
		),
		errorElement: <ErrorElement />,
	},
	{
		path: PATH_NOTFOUND,
		element: <NotfoundPage />,
		errorElement: <ErrorElement />,
	},
]);
