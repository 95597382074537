/* eslint-disable react/prop-types */
import React from "react";
import { Radio } from "antd";
import styled from "styled-components";

export const RadioGroup = ({ bordered = false, ...props }) => {
	return <StyledRadioGroup bordered={bordered} {...props} />;
};

const StyledRadioGroup = styled(Radio.Group)`
	.ant-radio-wrapper {
		margin-inline-end: 24px;
		border: ${({ bordered }) => (bordered ? "1px solid #e9e8e8" : 0)};
		border-radius: 8px;
		padding: ${({ bordered }) => (bordered ? " 12px 20px" : 0)};
	}
	.ant-radio-wrapper .ant-radio-inner {
		background-color: #ffffff;
	}
	.ant-radio-wrapper .ant-radio-inner::after {
		background-color: #265D71;
		transform: scale(0.475);
	}
	.ant-radio-wrapper-checked {
		border-color: ${({ bordered }) => (bordered ? "#265D71" : "none")};
	}
`;
