import React from "react";
import { Input } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

export const InputTextArea = ({ minRows = 4, maxRows= 8, ...props }) => {
	return <StyledTextArea autoSize={{ minRows: minRows, maxRows: maxRows }} {...props} />;
};

const StyledTextArea = styled(TextArea)`
	font-family: DMSans-Regular;
	padding: 12px 16px;
	border-radius: 12px;
`;
