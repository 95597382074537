/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled from "styled-components";

export const ClampLines = ({ text = "", clamp = 120 }) => {
	const [isMore, setIsMore] = useState(false);

	return (
		<Text>
			{isMore ? text : text.slice(0, clamp)}
			{text.length > clamp && (
				<Button
					onClick={(e) => {
						e?.stopPropagation();
						setIsMore(!isMore);
					}}
				>
					{isMore ? " less." : "...more"}
				</Button>
			)}
		</Text>
	);
};

const Text = styled.span`
	overflow: hidden;
	color: #595959;
	font-weight: 400;
	font-family: DMSans-Regular;
	font-size: 12px;
	line-height: 20px;
`;

const Button = styled.span`
	cursor: pointer;
	color: #265D71;
	font-weight: 400;
	font-family: DMSans-Regular;
	font-size: 14px;
	line-height: 20px;
`;
