/* eslint-disable react/prop-types */
import React from "react";

export const IconProfileSvg = ({ color = "#595959", ...props }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.0039 2.8C8.23663 2.8 6.80391 4.23269 6.80391 6C6.80391 7.76731 8.23663 9.2 10.0039 9.2C11.7712 9.2 13.2039 7.76731 13.2039 6C13.2039 4.23269 11.7712 2.8 10.0039 2.8ZM6.00391 6C6.00391 3.79086 7.79477 2 10.0039 2C12.213 2 14.0039 3.79086 14.0039 6C14.0039 8.20914 12.213 10 10.0039 10C7.79477 10 6.00391 8.20914 6.00391 6Z"
				fill={color}
				stroke={color}
				strokeWidth="0.5"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.6293 13.9749C12.4857 11.773 7.51898 11.773 3.37546 13.9749C3.26895 14.0315 3.20238 14.1423 3.20238 14.2629V17.2003C3.20238 17.4213 3.38147 17.6003 3.60238 17.6003H16.4024C16.6233 17.6003 16.8024 17.4213 16.8024 17.2003V14.2629C16.8024 14.1423 16.7358 14.0315 16.6293 13.9749ZM3.00002 13.2684C7.37834 10.9418 12.6263 10.9418 17.0047 13.2684C17.3724 13.4639 17.6023 13.8463 17.6023 14.2629V17.2003C17.6023 17.8631 17.0651 18.4003 16.4023 18.4003H3.60234C2.9396 18.4003 2.40234 17.8631 2.40234 17.2003V14.2629C2.40234 13.8463 2.63223 13.4639 3.00002 13.2684Z"
				fill={color}
				stroke={color}
				strokeWidth="0.5"
			/>
		</svg>
	);
};
