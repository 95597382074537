/* eslint-disable react/prop-types */
import React from "react";

export const IconLogoutSvg = ({ color = "#A8A8A8", ...props }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M4.16667 18.3337H9.16667C9.38768 18.3337 9.59964 18.2459 9.75592 18.0897C9.9122 17.9334 10 17.7214 10 17.5004C10 17.2794 9.9122 17.0674 9.75592 16.9112C9.59964 16.7549 9.38768 16.6671 9.16667 16.6671H4.16667C3.94565 16.6671 3.73369 16.5793 3.57741 16.423C3.42113 16.2667 3.33333 16.0548 3.33333 15.8337V4.16707C3.33333 3.94606 3.42113 3.7341 3.57741 3.57782C3.73369 3.42154 3.94565 3.33374 4.16667 3.33374H9.16667C9.38768 3.33374 9.59964 3.24594 9.75592 3.08966C9.9122 2.93338 10 2.72142 10 2.50041C10 2.27939 9.9122 2.06743 9.75592 1.91115C9.59964 1.75487 9.38768 1.66707 9.16667 1.66707H4.16667C3.50362 1.66707 2.86774 1.93046 2.3989 2.39931C1.93006 2.86815 1.66667 3.50403 1.66667 4.16707V15.8337C1.66667 16.4968 1.93006 17.1327 2.3989 17.6015C2.86774 18.0703 3.50362 18.3337 4.16667 18.3337Z"
				fill={color}
			/>
			<path
				d="M13.5793 7.25617C13.4997 7.1793 13.4362 7.08735 13.3926 6.98568C13.3489 6.88401 13.3259 6.77466 13.3249 6.66401C13.324 6.55336 13.3451 6.44363 13.387 6.34121C13.4289 6.2388 13.4907 6.14575 13.569 6.06751C13.6472 5.98927 13.7403 5.92739 13.8427 5.88549C13.9451 5.84359 14.0548 5.8225 14.1655 5.82346C14.2761 5.82443 14.3855 5.84741 14.4871 5.89109C14.5888 5.93476 14.6808 5.99825 14.7576 6.07784L18.091 9.41117C18.2472 9.56745 18.335 9.77937 18.335 10.0003C18.335 10.2213 18.2472 10.4332 18.091 10.5895L14.7576 13.9228C14.6808 14.0024 14.5888 14.0659 14.4871 14.1096C14.3855 14.1533 14.2761 14.1763 14.1655 14.1772C14.0548 14.1782 13.9451 14.1571 13.8427 14.1152C13.7403 14.0733 13.6472 14.0114 13.569 13.9332C13.4907 13.8549 13.4289 13.7619 13.387 13.6595C13.3451 13.5571 13.324 13.4473 13.3249 13.3367C13.3259 13.226 13.3489 13.1167 13.3926 13.015C13.4362 12.9133 13.4997 12.8214 13.5793 12.7445L15.4901 10.8337H5.83514C5.61412 10.8337 5.40216 10.7459 5.24588 10.5896C5.0896 10.4333 5.0018 10.2214 5.0018 10.0003C5.0018 9.77933 5.0896 9.56736 5.24588 9.41108C5.40216 9.2548 5.61412 9.16701 5.83514 9.16701H15.4901L13.5793 7.25617Z"
				fill={color}
			/>
		</svg>
	);
};
