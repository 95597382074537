/* eslint-disable react/prop-types */
import React from "react";

export const IconInfoFilledSvg = ({ color = "#1A5AFF", ...props }) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346628 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9959 5.87952 15.1518 3.84705 13.6524 2.34764C12.153 0.848226 10.1205 0.00406613 8 0ZM7.84616 3.69231C8.02872 3.69231 8.20719 3.74644 8.35899 3.84787C8.51079 3.9493 8.6291 4.09347 8.69897 4.26214C8.76883 4.43081 8.78711 4.61641 8.7515 4.79547C8.71588 4.97453 8.62796 5.139 8.49887 5.2681C8.36977 5.39719 8.2053 5.48511 8.02624 5.52072C7.84718 5.55634 7.66158 5.53806 7.49291 5.46819C7.32424 5.39833 7.18007 5.28002 7.07864 5.12822C6.97722 4.97642 6.92308 4.79795 6.92308 4.61538C6.92308 4.37057 7.02033 4.13578 7.19344 3.96267C7.36655 3.78956 7.60134 3.69231 7.84616 3.69231ZM8.61539 12.3077H8C7.83679 12.3077 7.68027 12.2429 7.56486 12.1274C7.44945 12.012 7.38462 11.8555 7.38462 11.6923V8C7.22141 8 7.06488 7.93516 6.94947 7.81976C6.83407 7.70435 6.76923 7.54782 6.76923 7.38461C6.76923 7.2214 6.83407 7.06488 6.94947 6.94947C7.06488 6.83406 7.22141 6.76923 7.38462 6.76923H8C8.16321 6.76923 8.31974 6.83406 8.43514 6.94947C8.55055 7.06488 8.61539 7.2214 8.61539 7.38461V11.0769C8.7786 11.0769 8.93512 11.1418 9.05053 11.2572C9.16594 11.3726 9.23077 11.5291 9.23077 11.6923C9.23077 11.8555 9.16594 12.012 9.05053 12.1274C8.93512 12.2429 8.7786 12.3077 8.61539 12.3077Z"
				fill={color}
			/>
		</svg>
	);
};
