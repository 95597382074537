/* eslint-disable react/prop-types */
import React from "react";

export const IconNotificationSvg = ({ color = "#A8A8A8", ...props }) => {
	return (
		<svg
			cursor="pointer"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M16.5991 13.7066C16.0238 13.7066 15.5558 13.2385 15.5558 12.6632V8.99397C15.5558 6.60408 14.0214 4.56614 11.8861 3.81271V2.82285C11.8861 1.81773 11.0684 1 10.0632 1C9.05813 1 8.24043 1.81773 8.24043 2.82285V3.81271C6.10514 4.56614 4.57068 6.60405 4.57068 8.99397V12.6632C4.57068 13.2385 4.10264 13.7066 3.52734 13.7066C3.23611 13.7066 3 13.9427 3 14.2339V16.2718C3 16.563 3.23611 16.7991 3.52734 16.7991H8.06102V16.9978C8.06102 18.1018 8.95923 19 10.0632 19C11.1673 19 12.0655 18.1018 12.0655 16.9978V16.7991H16.5991C16.8904 16.7991 17.1265 16.563 17.1265 16.2718V14.2339C17.1265 13.9426 16.8904 13.7066 16.5991 13.7066ZM9.29511 2.82285C9.29511 2.39929 9.63968 2.05469 10.0632 2.05469C10.4868 2.05469 10.8314 2.39929 10.8314 2.82285V3.55537C10.5803 3.52007 10.3239 3.5014 10.0632 3.5014C9.80256 3.5014 9.5462 3.52007 9.29511 3.55537V2.82285ZM11.0108 16.9978C11.0108 17.5202 10.5857 17.9453 10.0632 17.9453C9.54079 17.9453 9.11571 17.5202 9.11571 16.9978V16.7991H11.0108V16.9978ZM16.0718 15.7444H4.05469V14.6941C4.95708 14.4596 5.62536 13.638 5.62536 12.6632V8.99393C5.62536 6.54688 7.61619 4.55605 10.0632 4.55605C12.5103 4.55605 14.5011 6.54688 14.5011 8.99393V12.6632C14.5011 13.638 15.1694 14.4596 16.0718 14.6941V15.7444Z"
				fill={color}
				stroke={color}
				strokeWidth="0.4"
			/>
		</svg>
	);
};
