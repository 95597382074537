/* eslint-disable react/prop-types */
import React from "react";

export const IconArrowUpSvg = ({ color = "#A8A8A8", ...props }) => {
	return (
		<svg width="12" height="8" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M4.99994 0.614033C5.15356 0.614033 5.30716 0.672688 5.42428 0.789751L9.10981 4.47532C9.34426 4.70977 9.34426 5.08988 9.10981 5.32424C8.87546 5.55859 8.49541 5.55859 8.26095 5.32424L4.99994 2.06304L1.73892 5.32412C1.50447 5.55847 1.12447 5.55847 0.890138 5.32412C0.655576 5.08977 0.655576 4.70965 0.890138 4.4752L4.57561 0.789636C4.69279 0.672555 4.84638 0.614033 4.99994 0.614033Z"
				fill={color}
			/>
		</svg>
	);
};
