import React from "react";
import { compact, words } from "lodash";
import { BOOKING_STATUS, TOAST_MESSAGE_TYPE, USER_TYPE } from "./constants";
import { Row, notification } from "antd";
import {
	Typography,
	IconCloseSvg,
	IconInfoFilledSvg,
	IconErrorFilledSvg,
	IconWarningFilledSvg,
} from "components/elements";
import { IconSuccessFilledSvg } from "components/elements/Svgs/IconSuccessFilledSvg";
import moment from "moment-timezone";
import styled from "styled-components";

export function capitalizeStrings(text = "", lowerCase = true, splitText = "_") {
	const words = text.trim().split(splitText);
	const newWords = words.map((word) =>
		lowerCase
			? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
			: (word.charAt(0) + word.slice(1)).toUpperCase()
	);
	return newWords.join(" ");
}

export const trimString = (stringArr = [], joinTxt = ", ") => {
	const newStrings = compact(stringArr)?.map((text) => text?.trim?.() || text);
	if (newStrings.length > 0) {
		return newStrings.join(joinTxt);
	}
	return "";
};

export const formatCurrency = (value, currency, maximumFractionDigits) => {
	try {
		const data = new Intl.NumberFormat("us-US", {
			style: "decimal",
			currency: currency || "VND",
			maximumFractionDigits: maximumFractionDigits || undefined,
		}).format(value);
		return `${data} VND`;
	} catch (e) {
		return `${value} VND`;
	}
};

export const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = (error) => reject(error);
	});

export function getAvatarShortenedName(name) {
	if (typeof name === "string" && name.trim() !== "") {
		const ws = words(name);
		const { length } = ws;
		let sh;
		if (length > 1) {
			sh = ws[0].charAt(0) + ws[length - 1].charAt(0);
		} else {
			sh = ws[0].charAt(0) + ws[0].charAt(ws[0].length - 1);
		}
		return sh.toUpperCase();
	}
	return "";
}

export const toast = ({ type, message }) => {
	const configStyle = {
		boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.06)",
		borderRadius: 12,
		padding: "16px 24px",
	};

	switch (type) {
		case TOAST_MESSAGE_TYPE.SUCCESS:
			notification.success({
				message: (
					<Typography variant="label1" color="#018E42">
						SUCCESS
					</Typography>
				),
				description: (
					<Typography variant="body3" top={-4} color="#018E42">
						{message}
					</Typography>
				),
				icon: (
					<Row align="middle" justify="center" style={{ width: 22, height: 22 }}>
						<IconSuccessFilledSvg color="#018E42" />
					</Row>
				),
				style: {
					backgroundColor: "#ECFDF5",
					borderLeftColor: "#018E42",
					...configStyle,
				},
				closeIcon: <IconCloseSvg />,
			});
			break;
		case TOAST_MESSAGE_TYPE.INFO:
			notification.info({
				message: (
					<Typography variant="label1" color="#0C99EC">
						INFO
					</Typography>
				),
				description: (
					<Typography variant="body3" top={-4} color="#0C99EC">
						{message}
					</Typography>
				),
				icon: (
					<Row align="middle" justify="center" style={{ width: 22, height: 22 }}>
						<IconInfoFilledSvg />
					</Row>
				),
				style: {
					backgroundColor: "#EEF3FF",
					borderLeftColor: "#0C99EC",
					...configStyle,
				},
				closeIcon: <IconCloseSvg />,
			});
			break;
		case TOAST_MESSAGE_TYPE.ERROR:
			notification.error({
				message: (
					<Typography variant="label1" color="#BF1A2F">
						ERROR
					</Typography>
				),
				description: (
					<Typography variant="body3" top={-4} color="#BF1A2F">
						{message}
					</Typography>
				),
				icon: (
					<Row align="middle" justify="center" style={{ width: 22, height: 22 }}>
						<IconErrorFilledSvg color="#BF1A2F" />
					</Row>
				),
				style: {
					backgroundColor: "#FFF2F2",
					borderLeftColor: "#BF1A2F",
					...configStyle,
				},
				closeIcon: <IconCloseSvg />,
			});
			break;
		case TOAST_MESSAGE_TYPE.WARNING:
			notification.warning({
				message: (
					<Typography variant="label1" color="#FFB11B">
						WARNING
					</Typography>
				),
				description: (
					<Typography variant="body3" top={-4} color="#FFB11B">
						{message}
					</Typography>
				),
				icon: (
					<Row align="middle" justify="center" style={{ width: 22, height: 22 }}>
						<IconWarningFilledSvg color="#FFB11B" />
					</Row>
				),
				style: {
					backgroundColor: "#FFF9EB",
					borderLeftColor: "#FFB11B",
					...configStyle,
				},
				closeIcon: <IconCloseSvg />,
			});
			break;
		default:
			notification.warning({
				message: (
					<Typography variant="label1" color="#FFB11B">
						WARNING
					</Typography>
				),
				description: (
					<Typography variant="body3" top={-4} color="#FFB11B">
						{message}
					</Typography>
				),
				icon: (
					<Row align="middle" justify="center" style={{ width: 22, height: 22 }}>
						<IconWarningFilledSvg />
					</Row>
				),
				style: {
					backgroundColor: "#FFF9EB",
					borderLeftColor: "#FFB11B",
					...configStyle,
				},
				closeIcon: <IconCloseSvg />,
			});
			break;
	}
};

export const numberWithCommas = (x = 0) => {
	const num = x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
	return `${num}`;
};

export const getColorStatus = (type) => {
	let color = "#fff";
	let colorText = "#fff";
	switch (type) {
		case BOOKING_STATUS.CREATED:
			color = "#fcbdbd";
			break;
		case BOOKING_STATUS.DEPOSITED:
			color = "#2db7f5";
			break;
		case BOOKING_STATUS.CHECKED_IN:
			color = "#ccc499";
			break;
		case BOOKING_STATUS.TODAY:
			color = "#ccc499";
			break;
		case BOOKING_STATUS.CHECKED_OUT:
			color = "#fff494";
			colorText = "#000";
			break;
		case BOOKING_STATUS.CLEANING:
			color = "#ffcd85";
			break;
		case BOOKING_STATUS.CONFIRMED:
			color = "#00c851";
			break;
		case BOOKING_STATUS.COMPLETED:
			color = "#007e33";
			break;
		case BOOKING_STATUS.CANCELLED:
			color = "#c9ccc4";
			break;
		case BOOKING_STATUS.EXPIRED:
			color = "#f50";
			break;
		default:
			break;
	}
	return [color, colorText];
};

export const formatApartmentNo = (apartmentNo) => {
	return parseInt(apartmentNo, 10) < 10 ? `0${apartmentNo}` : apartmentNo;
};

export const getApartmentFloorAndNo = (apartment) => {
	return `${apartment.block}-${apartment.floor}.${formatApartmentNo(apartment.no)}`;
};

export const searchApartmentByCode = (apartment, input) => {
	const value1 = `${apartment.block}-${apartment.floor}.${formatApartmentNo(apartment.no)}`;
	const value2 = `${apartment.block}-${apartment.floor}.${apartment.no}`;
	return value1.toLowerCase().includes(input.toLowerCase()) || value2.toLowerCase().includes(input.toLowerCase());
};

export const sortApartmentAsFloor = (a, b) => {
	const floorA = a.floor === '12A' ? 13 : parseInt(a.floor);
	const floorB = b.floor === '12A' ? 13 : parseInt(b.floor);
	return floorA - floorB;
};

export const totalRevenueAfterDeposit = (booking) => {
	const subTotal = booking?.invoice?.price_component?.sub_total || 0;
	const deposit = booking?.invoice?.deposited_amount || 0;
	let final = subTotal - deposit;
	// affiliate
	if (booking.affiliate?.user_type === USER_TYPE.PARTNER && booking?.affiliate?.total > 0) {
		final -= booking?.affiliate?.total;
	}
	// discount
	if (booking?.invoice?.price_component?.discount_total > 0) {
		final -= booking.invoice.price_component.discount_total;
	}

	return final.toFixed(0);
};

export const totalSaleCOD = (booking) => {
	const saleCod = booking?.invoice?.price_component?.sale_cod;
	if (saleCod === 0 || isNaN(saleCod)) {
		return 0;
	}
	const revenueAfterDeposit = totalRevenueAfterDeposit(booking) || 0;
	const totalSaleCOD = saleCod - revenueAfterDeposit;
	return totalSaleCOD.toFixed(0);
};


export const isSameDate = (inputDate) => {
	return moment().isSame(inputDate, "month") && moment().isSame(inputDate, "day");
};

export const HotFlag = styled.div`
margin-left: 10px;
margin-bottom: 10px;
.server-status {
	margin-left: 0px;
	position: absolute;
	vertical-align: middle;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
.server-status::before,
.server-status::after {
	left: 0;
	top: 50%;
	margin-left: -1px;
	margin-top: -6px;
	position: absolute;
	vertical-align: middle;
	width: 12px;
	height: 12px;
	border-radius: 50%;
}
.server-status,
.server-status::before,
.server-status::after {
	background: #ff2c2c; /* red */
}

.server-status::before {
	content: "";
	animation: bounce 1.5s infinite;
}
.server-status::after {
	content: "";
	animation: bounce 1.5s -0.4s infinite;
}

@keyframes bounce {
	0% {
		transform: scale(1);
		-webkit-transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		-webkit-transform: scale(2);
		opacity: 0;
	}
}

@-webkit-keyframes bounce {
	0% {
		transform: scale(1);
		-webkit-transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		-webkit-transform: scale(2);
		opacity: 0;
	}
}
`;
export const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "VND",
});

export const isPriceEditable = (booking) => {
	return (booking?.status === BOOKING_STATUS.CREATED || booking?.status === BOOKING_STATUS.DEPOSITED || booking?.status === BOOKING_STATUS.CONFIRMED);
};

export const isPriceEditableLevel2 = (isEdit, booking) => {
	return isEdit && (booking?.status === BOOKING_STATUS.CREATED || booking?.status === BOOKING_STATUS.DEPOSITED);
};

export const isSaleCodEditable = (booking) => {
	return booking?.status === BOOKING_STATUS.CREATED || booking?.status === BOOKING_STATUS.DEPOSITED || booking?.status === BOOKING_STATUS.CONFIRMED;
};

export const isFinanceAdmin = (admin) => {
	const emails = ["dathaonguyen2k11@gmail.com", "mymytran0492@gmail.com", "haidtimeo@gmail.com"];
	return emails.includes(admin.email);
};

export const removeDynamicPriceInfo = (text, target) => {
	// Split the text into an array of lines
	const lines = text.split('\n');

	// Regular expression to match 'giá Sale: xxxk' where xxx is any number
	// const regex = /giá Sale: \d+k/g;

	// const regex = target === USER_TYPE.CUSTOMER ? /giá Sale: \d+k,hình căn hộ: https?:\/\/[^\s]+/g : /giá web: \d+k /g;
	const regex = target === USER_TYPE.CUSTOMER ? /giá Sale: \d+k,hình căn hộ: https:\/\/zalo\.me\/g\/[a-zA-Z0-9]+/g : /giá web: \d+k |,link web: https:\/\/thesongreal\.com\/apartments\/details\/[a-z0-9]+/g;
	// Iterate over each line and remove the pattern
	const modifiedLines = lines.map(line => line.replace(regex, '').trim());

	// Join the modified lines back into a single string
	const modifiedText = modifiedLines.join('\n');
	return modifiedText;
};
