import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { handleApi } from "configs/handleApi";
import { ACCESS_TOKEN } from "utils/constants";

const actionPostSignIn = createAction("AUTH_SIGN_IN");
const actionGetAdminInfo = createAction("AUTH_GET_ADMIN_INFO");

export const apiPostSignIn = createAsyncThunk(actionPostSignIn.toString(), async (data) => {
	const response = await handleApi({ url: "/admin/login", method: "POST", data });
	if (response?.data) {
		const accessToken = response.data?.access_token;
		if (accessToken) localStorage.setItem(ACCESS_TOKEN, accessToken);
	}
	return response?.data;
});

export const apiGetAdminInfo = createAsyncThunk(actionGetAdminInfo.toString(), async () => {
	return await handleApi({ url: "/private/users/me/info" });
});
