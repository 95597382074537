/* eslint-disable react/prop-types */
import React from "react";

export const IconSuccessFilledSvg = ({ color = "#0F9E60", ...props }) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346628 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9975 5.87902 15.1539 3.84561 13.6541 2.34585C12.1544 0.846093 10.121 0.00245139 8 0ZM11.8101 6.59916L7.29688 10.9068C7.18212 11.016 7.02979 11.0769 6.8714 11.0769C6.713 11.0769 6.56067 11.016 6.44592 10.9068L4.18991 8.753C4.07204 8.6401 4.00381 8.48503 4.0002 8.32186C3.99659 8.15869 4.05789 8.00076 4.17064 7.88275C4.28339 7.76474 4.43837 7.69631 4.60154 7.6925C4.76471 7.68868 4.92272 7.74978 5.04087 7.86238L6.8714 9.61057L10.9591 5.70853C11.0773 5.59593 11.2353 5.53483 11.3985 5.53865C11.5616 5.54247 11.7166 5.6109 11.8294 5.7289C11.9421 5.84691 12.0034 6.00484 11.9998 6.16802C11.9962 6.33119 11.928 6.48626 11.8101 6.59916Z"
				fill={color}
			/>
		</svg>
	);
};
