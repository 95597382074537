import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { handleApi } from "configs/handleApi";
import { PAGE_SIZE_LIMIT } from "utils/constants";

const actionGetListSystemPrices = createAction("SETTING_GET_SYSTEM_PRICE_LIST");
const actionGetListServices = createAction("SETTING_GET_SERVICE_LIST");
const actionAddListSystemPrice = createAction("SETTING_ADD_SYSTEM_PRICE");
const actionAddListServices = createAction("SETTING_ADD_BENEFIT");
const actionUpdateListSystemPrice = createAction("SETTING_UPDATE_SYSTEM_PRICE");
const actionUpdateListServices = createAction("SETTING_UPDATE_BENEFIT");

const actionGetListBenefits = createAction("SETTING_GET_BENEFIT_LIST");
const actionAddListBenefits = createAction("SETTING_ADD_BENEFIT");
const actionUpdateListBenefits = createAction("SETTING_UPDATE_BENEFIT");

// services
export const apiGetListServiceSetting = createAsyncThunk(actionGetListServices.toString(), async (params = {}) => {
	const response = await handleApi({ url: "/private/services", params: { limit: PAGE_SIZE_LIMIT, ...params } });
	return response;
});

export const apiGetBookingServices = async (params = {}) => {
	const response = await handleApi({ url: "/private/services", params: { limit: 10000, ...params } });
	return response;
};

export const apiAddServiceSetting = createAsyncThunk(actionAddListServices.toString(), async (payload = {}) => {
	const response = await handleApi({ url: "/private/services", method: "POST", data: payload });
	return response;
});

export const apiUpdateServiceSetting = createAsyncThunk(
	actionUpdateListServices.toString(),
	async ({ serviceId, payload = {} }) => {
		const response = await handleApi({ url: `/private/services/${serviceId}`, method: "PUT", data: payload });
		return response;
	}
);

export const apiDeleteServiceSetting = async (uid) => {
	const response = await handleApi({ url: `/private/services/${uid}`, method: "DELETE" });
	return response;
};

// benefits
export const apiGetListBenefitSetting = createAsyncThunk(actionGetListBenefits.toString(), async (params = {}) => {
	const response = await handleApi({ url: "/private/benefits", params: { limit: PAGE_SIZE_LIMIT, ...params } });
	return response;
});

export const apiAddBenefitSetting = createAsyncThunk(actionAddListBenefits.toString(), async (payload = {}) => {
	const response = await handleApi({ url: "/private/benefits", method: "POST", data: payload });
	return response;
});

export const apiDeleteBenefitSetting = async (uid) => {
	const response = await handleApi({ url: `/private/benefits/${uid}`, method: "DELETE" });
	return response;
};

export const apiUpdateBenefitSetting = createAsyncThunk(actionUpdateListBenefits.toString(), async (payload = {}) => {
	const response = await handleApi({ url: "/private/benefits", method: "PUT", data: payload });
	return response;
});

export const apiUploadIconImage = async ({ type, targetId, file }) => {
	const formData = new FormData();
	formData.append("file", file?.originFileObj ?? file);
	return await handleApi({
		url: `/private/icons/${type}/${targetId}/upload`,
		params: { album: "ICON" },
		data: formData,
		method: "POST",
	});
};

// system prices
export const apiGetListSystemPrice = createAsyncThunk(actionGetListSystemPrices.toString(), async (params = {}) => {
	const response = await handleApi({ url: "/private/system/price", params: { limit: PAGE_SIZE_LIMIT, ...params } });
	return response;
});

export const apiAddSystemPrice = createAsyncThunk(actionAddListSystemPrice.toString(), async (payload = {}) => {
	const response = await handleApi({ url: "/private/system/price", method: "POST", data: payload });
	return response;
});

export const apiUpdateSystemPrice = createAsyncThunk(
	actionUpdateListSystemPrice.toString(),
	async ({ payload = {} }) => {
		const response = await handleApi({ url: `/private/system/price`, method: "PUT", data: payload });
		return response;
	}
);

// params from_date=XXX&to_date=YYY
export const apiGetPrivatePriceDates = async (squareType, params = {}) => {
	const response = await handleApi({ url: `/private/system/price/${squareType}/calendar`, params: { ...params } });
	return response;
};

export const apiDeleteSystemPrice = async (uid) => {
	const response = await handleApi({ url: `/private/system/price/${uid}`, method: "DELETE" });
	return response;
};

export const apiCreateUser = (params = {}) => {
	return handleApi({ url: "/private/saler/register", method: "POST", data: params });
};

export const apiEditUser = ({ id, ...params } = { id: "" }) => {
	return handleApi({ url: "/private/users/" + id, method: "PUT", data: params });
};

export const apiDeleteUser = ({ id } = { id: "" }) => {
	return handleApi({ url: "/private/users/" + id, method: "DELETE" });
};

export const apiDeleteSalerOrPartner = ({ id } = { id: "" }) => {
	return handleApi({ url: "/private/saler_or_partner/" + id, method: "DELETE" });
};

export const updateBatchPartnerAffiliate = (params = {}) => {
	return handleApi({ url: "/private/partners/affiliate", method: "PUT", data: params });
};