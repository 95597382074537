import { configureStore } from "@reduxjs/toolkit";

import { authReducer } from "stores/auth/reducer";
import { commonReducer } from "stores/common/reducer";
import { apartmentReducer } from "stores/apartment/reducer";
import { bookingReducer } from "stores/booking/reducer";
import { customerReducer } from "stores/customer/reducer";
import { settingReducer } from "stores/settings/reducer";

export const store = configureStore({
	reducer: {
		authReducer,
		commonReducer,
		apartmentReducer,
		bookingReducer,
		customerReducer,
		settingReducer,
	},
});
