import { createDraftSafeSelector, createReducer } from "@reduxjs/toolkit";
import { apiGetListServiceSetting, apiGetListBenefitSetting, apiGetListSystemPrice } from "./apis";

const INITIAL_STATE = {
	serviceList: {},
	benefitList: {},
	systemPriceList: {},
};

export const settingReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(apiGetListSystemPrice.fulfilled, (state, action) => {
		state.systemPriceList = action.payload;
	});
	builder.addCase(apiGetListServiceSetting.fulfilled, (state, action) => {
		state.serviceList = action.payload;
	});
	builder
		.addCase(apiGetListBenefitSetting.fulfilled, (state, action) => {
			state.benefitList = action.payload;
		})
		.addDefaultCase(() => {});
});

export const settingSelector = createDraftSafeSelector(
	(state) => state.settingReducer,
	(state) => state
);
