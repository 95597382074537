import { createDraftSafeSelector, createReducer } from "@reduxjs/toolkit";
import { apiGetListCustomer, apiGetCustomerDetail, apiGetListCustomerContacts } from "./apis";

const INITIAL_STATE = {
	customerList: {},
	customerDetail: {},
	customerContactList: {},
};

export const customerReducer = createReducer(INITIAL_STATE, (builder) => {
	builder
		.addCase(apiGetListCustomer.fulfilled, (state, action) => {
			state.customerList = action.payload;
		})
		.addCase(apiGetListCustomerContacts.fulfilled, (state, action) => {
			state.customerContactList = action.payload;
		})
		.addCase(apiGetCustomerDetail.fulfilled, (state, action) => {
			state.customerDetail = action.payload;
		})
		.addDefaultCase(() => {});
});

export const customerSelector = createDraftSafeSelector(
	(state) => state.customerReducer,
	(state) => state
);
