import { createDraftSafeSelector, createReducer } from "@reduxjs/toolkit";

import { apiGetListApartment, apiGetApartmentDetail } from "./apis";

const INITIAL_STATE = {
	apartmentList: [],
	apartmentDetail: {},
};

export const apartmentReducer = createReducer(INITIAL_STATE, (builder) => {
	builder
		.addCase(apiGetListApartment.fulfilled, (state, action) => {
			state.apartmentList = action.payload;
		})
		.addCase(apiGetApartmentDetail.fulfilled, (state, action) => {
			state.apartmentDetail = action.payload;
		})
		.addDefaultCase(() => {});
});

export const apartmentSelector = createDraftSafeSelector(
	(state) => state.apartmentReducer,
	(state) => state
);
