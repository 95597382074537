/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

export const IconMarkerSvg = ({ color = "#265D71", icao = "ABCD" }) => {
	return (
		<div style={{ display: "flex" }}>
			<Container>
				<Icao style={{ backgroundColor: color }}>{icao}</Icao>
				<svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M5.5 6L0.699997 0H10.2L5.5 6Z" fill={color} />
				</svg>
			</Container>
		</div>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 8px;
	justify-content: center;
`;

const Icao = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-family: DMSans-Bold;
	font-size: 12px;
	line-height: 18px;
	padding: 3px 5px 2px 5px;
	border-radius: 4px;
	box-shadow: 0px 4px 8px rgba(8, 30, 39, 0.12429);
	margin-bottom: -2px;
`;
