/* eslint-disable react/prop-types */
import React from "react";
import { Collapse as AntCollapse } from "antd";
import styled from "styled-components";
import { Typography } from "../Typography";
import { IconArrowDownSvg, IconArrowUpSvg } from "../Svgs";

const { Panel } = AntCollapse;

export const Collapse = ({ header = "", children, headerColor = "#F9F9F9", boxColor = "#ffffff" }) => {
	return (
		<StyledCollapse
			defaultActiveKey={["1"]}
			expandIconPosition="end"
			expandIcon={({ isActive }) => (isActive ? <IconArrowUpSvg /> : <IconArrowDownSvg />)}
			headerColor={headerColor}
			boxColor={boxColor}
		>
			<Panel
				header={
					<Typography variant="sub4" color="#222222">
						{header}
					</Typography>
				}
				key="1"
			>
				{children}
			</Panel>
		</StyledCollapse>
	);
};

const StyledCollapse = styled(AntCollapse)`
	border: 0;
	border-radius: 4px;
	background-color: transparent;
	.ant-collapse-item > .ant-collapse-header {
		background: ${({ headerColor }) => headerColor};
		border-radius: 4px !important;
		font-size: 14px;
		line-height: 22px;
		color: #222222;
		font-family: DMSans-Medium;
		padding: 8px 16px;
	}
	.ant-collapse-item,
	.ant-collapse-content {
		border: 0;
	}
	.ant-collapse-content > .ant-collapse-content-box {
		padding: 0 0 16px;
		background: ${({ boxColor }) => boxColor};
	}
    .ant-collapse-item > .ant-collapse-header {
        padding: 8px 0;
    }
`;
