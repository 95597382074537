/* eslint-disable react/prop-types */
import React from "react";

export const IconPlusSvg = ({ color = "#cdd1d8", ...props }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M17.1999 9.19998H10.8V2.79995C10.8 2.35845 10.4416 2 9.99993 2C9.55842 2 9.19998 2.35845 9.19998 2.79995V9.19998H2.79995C2.35845 9.19998 2 9.55842 2 9.99993C2 10.4416 2.35845 10.8 2.79995 10.8H9.19998V17.1999C9.19998 17.6416 9.55842 18 9.99993 18C10.4416 18 10.8 17.6416 10.8 17.1999V10.8H17.1999C17.6416 10.8 18 10.4416 18 9.99993C18 9.55842 17.6416 9.19998 17.1999 9.19998Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
		</svg>
	);
};
