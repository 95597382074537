export const theme = {
	token: {
		colorPrimary: "#265D71",
		colorError: "#BF1A2F",
		colorInfo: "#0C99EC",
		colorSuccess: "#018E42",
		colorTextBase: "#222222",
		colorWarning: "#FFB11B",
		colorBgBase: "#fff",
		fontSize: 14,
		borderRadius: 8,
		colorBorder: "#E9E8E8",
		colorBgMask: "rgba(0, 0, 0, 0.4)",
		colorBgLayout: "#F9F9F9",
		colorBgContainerDisabled: "#F9F9F9",
		colorLoadingSecondary: "#A8A8A8",
		colorText300: "#595959",
		colorSplit: "#E9E8E8",
        controlItemBgActive: "rgba(34, 34, 34, 0.04)",
	},
	components: {
		// Menu: {
		// 	colorItemText: "#cdd1d8",
		// 	colorItemTextHover: "#cdd1d8",
		// 	colorItemBg: "#001529",
		// 	colorSubItemBg: "#001529",
		// 	colorItemBgSelected: "transparent",
		// 	colorItemTextSelected: "#265D71",
		// 	colorItemTextSelectedHorizontal: "#265D71",
		// },
		DatePicker: {
			borderRadius: 12,
			borderRadiusLG: 12,
			borderRadiusSM: 12,
			borderRadiusXS: 12,
			sizePopupArrow: 0,
		},
	},
};
