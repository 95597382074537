// @ts-nocheck
import React from "react";
import { InputNumber as AntInputNumber } from "antd";
import styled from "styled-components";

import { IconArrowDownSvg, IconArrowUpSvg } from "components/elements";

export const InputNumber = ({ size = "large", error, ...props }) => {
	return (
		<StyledInputNumber
			min={0}
			size={size}
			// type="number"
			formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
			parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
			controls={{ upIcon: <IconArrowUpSvg />, downIcon: <IconArrowDownSvg /> }}
			// formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
			// parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
			error={error}
			{...props}
		/>
	);
};

export const InputNumber1 = ({ error, ...props }) => {
	return (
		<StyledInputNumber1
			min={0}
			size="large"
			// type="number"
			formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
			parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
			controls={{ upIcon: <IconArrowUpSvg />, downIcon: <IconArrowDownSvg /> }}
			// formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
			// parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
			error={error}
			{...props}
		/>
	);
};

const StyledInputNumber = styled(AntInputNumber)`
	width: 100%;
	border-radius: 8px;
	border-color: ${({ error }) => (error ? "red !important" : "#dadada")};
	&.ant-input-number-affix-wrapper {
		padding-inline-start: 20px;
	}
	.ant-input-number-input {
		height: 44px !important;
		padding-left: 16px;
		padding-right: 12px;
	}
	.ant-input-number-input::placeholder {
		color: #a8a8a8;
		font-family: DMSans-Regular;
		font-size: 14px;
	}
	&.ant-input-number-affix-wrapper .ant-input-number-prefix {
		margin-inline-end: 12px;
	}
	.ant-input-number-handler-wrap {
		opacity: 1;
		margin-inline-end: 12px;
	}
	.ant-input-number-handler-down {
		border-block-start: 0;
	}
	.ant-input-number-handler {
		border-inline-start: 0;
	}
`;
const StyledInputNumber1 = styled(AntInputNumber)`
	width: 100%;
	border-radius: 6px;
	border-color: ${({ error }) => (error ? "red !important" : "#dadada")};
	&.ant-input-number-affix-wrapper {
		padding-inline-start: 20px;	
		
	}
	.ant-input-number-input {
		height: 38px !important;
		padding-left: 12px;
		padding-right: 12px;
		
	}
	.ant-input-number-input::placeholder {
		color: #a8a8a8;
		font-family: DMSans-Regular;
		font-size: 14px;
		align-item:center;
	}
	&.ant-input-number-affix-wrapper .ant-input-number-prefix {
		margin-inline-end: 12px;
	}
	.ant-input-number-handler-wrap {
		opacity: 1;
		margin-inline-end: 12px;
	}
	.ant-input-number-handler-down {
		border-block-start: 0;
	}
	.ant-input-number-handler {
		border-inline-start: 0;
	}
`;
