import React from "react";
import dayjs from "dayjs";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { theme } from "configs/theme";
import { store } from "configs/configureStore";
import { router } from "configs/router";
import { Loading } from "components/elements";
dayjs.extend(isSameOrAfter);

function App() {
	return (
		<ConfigProvider theme={theme}>
			<Provider store={store}>
				<RouterProvider router={router} fallbackElement={<Loading />} />
			</Provider>
		</ConfigProvider>
	);
}

export default App;
