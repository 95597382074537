import React from "react";
import { Input as AntInput } from "antd";
import styled from "styled-components";

export const Input = React.forwardRef(({ ...props }, ref) => {
	return <StyledInput ref={ref} {...props} />;
});

const StyledInput = styled(AntInput)`
	&.ant-input-affix-wrapper,
	&.ant-input {
		color: #65686f;
		font-family: DMSans-Regular;
		padding: 0 16px;
		font-size: 14px;
		height: 44px;
		border-radius: 5px;
		border-color: ${({ error }) => (error ? "red !important" : "#dadada")};
	}
	&.ant-input::placeholder {
		color: #a8a8a8;
		font-family: DMSans-Regular;
		font-size: 14px;
	}
	&.ant-input-affix-wrapper-sm .ant-input-prefix {
		margin-inline-end: 8px;
	}
	&.ant-input-affix-wrapper-sm,
	&.ant-input-sm {
		padding: 0 12px;
		height: 36px;
	}
	&.ant-input-affix-wrapper-lg .ant-input-prefix {
		margin-inline-end: 8px;
	}
	&.ant-input-affix-wrapper-lg,
	&.ant-input-lg {
		padding: 0 20px;
		height: 50px;
	}
	&.ant-input-affix-wrapper .ant-input-prefix {
		margin-inline-end: 8px;
	}
`;
