/* eslint-disable react/prop-types */
import React from "react";

export const IconArrowRightSvg = ({ color = "#595959", ...props }) => {
	return (
		<svg width="7" height="12" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8.31755 7.00019C8.31755 6.7544 8.2237 6.50864 8.0364 6.32125L2.13949 0.424404C1.76438 0.0492874 1.15619 0.0492873 0.781224 0.424404C0.406259 0.799368 0.406259 1.40743 0.781223 1.78258L5.99913 7.00019L0.781405 12.2178C0.406441 12.5929 0.40644 13.2009 0.781405 13.5759C1.15637 13.9512 1.76456 13.9512 2.13967 13.5759L8.03658 7.67912C8.22391 7.49164 8.31755 7.24588 8.31755 7.00019Z"
				fill={color}
			/>
		</svg>
	);
};
