/* eslint-disable react/prop-types */
import React from "react";

export const IconArrowLeftSvg = ({ color = "#595959", ...props }) => {
	return (
		<svg width="7" height="12" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M0.682454 7.00019C0.682454 6.7544 0.776301 6.50864 0.963602 6.32125L6.86051 0.424404C7.23562 0.0492874 7.84381 0.0492873 8.21878 0.424404C8.59374 0.799368 8.59374 1.40743 8.21878 1.78258L3.00087 7.00019L8.21859 12.2178C8.59356 12.5929 8.59356 13.2009 8.21859 13.5759C7.84363 13.9512 7.23544 13.9512 6.86033 13.5759L0.963419 7.67912C0.776089 7.49164 0.682454 7.24588 0.682454 7.00019Z"
				fill={color}
			/>
		</svg>
	);
};
