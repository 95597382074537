// @ts-nocheck
import React, { memo, useState } from "react";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import { Modal } from "antd";
import "./pictureGrid.css";
import UploadList from "antd/es/upload/UploadList";
import styled from "styled-components";

const SortableItem = SortableElement((params) => (
	<WrapperItem>
		<UploadList
			locale={{ previewFile: "", removeFile: "" }}
			showDownloadIcon={false}
			listType={params.props.listType}
			onPreview={params.onPreview}
			onRemove={params.onRemove}
			items={[params.item]}
			showPreviewIcon={false}
			showRemoveIcon={false}
		/>
	</WrapperItem>
));

const listStyle = {
	display: "flex",
	flexWrap: "wrap",
	maxWidth: "100%",
	gap: "20px",
};

const SortableList = SortableContainer((params) => {
	const items = params.items || [];
	return (
		<div style={listStyle}>
			{items.map((item, index) => (
				<SortableItem
					key={`${item?._id || item?.uid || index}`}
					index={index}
					item={item}
					props={params.props}
					onPreview={params.onPreview}
					onRemove={params.onRemove}
				/>
			))}
		</div>
	);
});

const PicturesGrid = memo(({ onChange: onFileChange, ...props }) => {
	const [previewImage, setPreviewImage] = useState("");
	const fileList = props?.fileList || [];
	const onSortEnd = ({ oldIndex, newIndex }) => {
		onFileChange({ fileList: arrayMove(fileList, oldIndex, newIndex) });
	};

	const onChange = ({ fileList: newFileList }) => {
		onFileChange({ fileList: newFileList });
	};

	const onRemove = (file) => {
		//
	};

	const onPreview = async (file) => {
		//
	};

	return (
		<WrapperPicturesGrid>
			<SortableList
				distance={1}
				items={fileList}
				onSortEnd={onSortEnd}
				axis="xy"
				helperClass="SortableHelper"
				props={props}
				onChange={onChange}
				onRemove={onRemove}
				onPreview={onPreview}
			/>
			<Modal visible={!!previewImage} footer={null} onCancel={() => setPreviewImage("")} bodyStyle={{ padding: 0 }}>
				<img style={{ width: "100%" }} alt="" src={previewImage} />
			</Modal>
		</WrapperPicturesGrid>
	);
});

export { PicturesGrid };

const WrapperPicturesGrid = styled.div`
	margin-top: 20px;
`;
const WrapperItem = styled.div`
	max-width: 200px;
	max-height: 120px;
	cursor: grab;

	.ant-upload-list-picture-card-container {
		width: 200px;
		height: 120px;
		border: 8px;
	}

	.ant-upload-list-picture .ant-upload-list-item,
	.ant-upload-list-picture-card .ant-upload-list-item {
		padding: 0px;
		border: none;
	}
	.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
	.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
		object-fit: cover;
	}
`;
