import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { handleApi } from "configs/handleApi";
import { PAGE_SIZE_LIMIT, PAGE_SIZE_UN_LIMIT } from "utils/constants";

const actionGetListCustomer = createAction("CUSTOMER_GET_CUSTOMER_LIST");
const actionGetListCustomerContact = createAction("CUSTOMER_GET_CUSTOMER_CONTACT_LIST");
const actionGetCustomerDetail = createAction("CUSTOMER_GET_CUSTOMER_DETAIL");

// @ts-ignore
export const apiGetListCustomer = createAsyncThunk(actionGetListCustomer.toString(), async (params = {}) => {
	// @ts-ignore
	const response = await handleApi({ url: "/private/users", params: { limit: 100000, ...params } });
	return response;
});

export const apiGetListCustomerContacts = createAsyncThunk(
	actionGetListCustomerContact.toString(),
	// @ts-ignore
	async (params = {}) => {
		// @ts-ignore
		const response = await handleApi({ url: "/private/contacts", params: { limit: 999, ...params } });
		return response;
	}
);

export const apiGetCustomerDetail = createAsyncThunk(actionGetCustomerDetail.toString(), async (uid) => {
	const response = await handleApi({ url: `/private/users/${uid}`, params: { limit: PAGE_SIZE_LIMIT } });
	return response;
});

// @ts-ignore
export const fetchSearchCustomers = async ({ key, user_type, ...params } = {}) => {
	const response = await handleApi({
		url: "/private/users/search",
		params: { q: key, user_type: user_type || "OWNER", ...params },
	});
	return response;
};

export const apiFetchUserOwners = async ({ limit = PAGE_SIZE_UN_LIMIT } = {}) => {
	const response = await handleApi({
		url: "/private/users",
		params: { limit: limit, user_type: "OWNER" },
	});
	return response;
};
