/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { noop } from "lodash";

import { Typography } from "../Typography";
import { IconCheckSvg } from "../Svgs";

export const Checkbox = ({ title = null, checked = false, disabled = false, defaultChecked = false, onChange = noop, ...props }) => {
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		setIsChecked(defaultChecked);
	}, [defaultChecked]);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	const handleChange = () => {
		if (onChange !== noop) {
			onChange(!isChecked);
		} else {
			setIsChecked(!isChecked);
		}
	};

	return (
		<Container disabled={disabled} onClick={handleChange} {...props}>
			{isChecked ? (
				<CheckboxChecked>
					<IconCheckSvg color="#ffffff" />
				</CheckboxChecked>
			) : (
				<CheckboxUncheck />
			)}
			{title && (
				<Typography variant="body3" left={8} right={8}>
					{title}
				</Typography>
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;

    ${({ disabled }) =>
        disabled &&
        `
        pointer-events: none;
        opacity: 0.4;
    `}
`;

const CheckboxUncheck = styled.div`
	width: 22px;
	height: 22px;
	border-radius: 6px;
	border: 1px solid #a8a8a8;
`;

const CheckboxChecked = styled.div`
	width: 22px;
	height: 22px;
	border-radius: 6px;
	background: #265D71;
	display: flex;
	align-items: center;
	justify-content: center;
`;
