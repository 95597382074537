import React from "react";
import { Radio as AntRadio } from "antd";
import styled from "styled-components";

export const Radio = ({ ...props }) => {
	return <StyledRadio {...props} />;
};

const StyledRadio = styled(AntRadio)`
	&.ant-radio-wrapper .ant-radio-inner {
		background-color: #ffffff;
	}
	&.ant-radio-wrapper .ant-radio-inner::after {
		background-color: #265D71;
		transform: scale(0.475);
	}
`;
