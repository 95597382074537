/* eslint-disable react/prop-types */
import React from "react";

export const IconUploadSvg = ({ color = "#265D71", ...props }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M18.0006 12.6665V16.2222C18.0006 17.2041 17.2047 18.0001 16.2228 18.0001H3.77785C2.79597 18.0001 2 17.2041 2 16.2222V12.6665"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.4439 6.44463L9.99931 2L5.55469 6.44463"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M9.99964 2V12.6671" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};
