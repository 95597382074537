/* eslint-disable react/prop-types */
import React from "react";

export const IconEditSvg = ({ color = "#A8A8A8", ...props }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M18.1928 1.60664L18.1711 1.58688C17.7568 1.20844 17.2198 1 16.6589 1C16.0301 1 15.4264 1.26617 15.0025 1.7302L6.98742 10.5051C6.91437 10.5851 6.85895 10.6795 6.8247 10.7822L5.88224 13.6075C5.77327 13.9342 5.82796 14.2954 6.02851 14.5738C6.23065 14.8544 6.55631 15.0219 6.89971 15.0219C7.04825 15.0219 7.19349 14.9914 7.33119 14.9311L10.06 13.7374C10.1592 13.694 10.2482 13.6302 10.3212 13.5503L18.3363 4.77541C19.1704 3.86236 19.1061 2.44096 18.1928 1.60664ZM7.68796 13.0856L8.24099 11.4277L8.28763 11.3766L9.3358 12.334L9.28916 12.3851L7.68796 13.0856ZM17.1936 3.73152L10.3797 11.1913L9.33152 10.2339L16.1454 2.77409C16.2786 2.62819 16.461 2.54781 16.6589 2.54781C16.8326 2.54781 16.9988 2.6124 17.1275 2.72993L17.1491 2.74969C17.4321 3.00812 17.452 3.44858 17.1936 3.73152Z"
				fill={color}
			/>
			<path
				d="M16.6376 8.13976C16.2102 8.13976 15.8637 8.48626 15.8637 8.91366V15.4839C15.8637 16.5692 14.9808 17.4521 13.8956 17.4521H4.51594C3.43067 17.4521 2.54781 16.5692 2.54781 15.4839V6.18049C2.54781 5.09528 3.43073 4.21236 4.51594 4.21236H11.305C11.7324 4.21236 12.0789 3.86585 12.0789 3.43845C12.0789 3.01105 11.7324 2.66455 11.305 2.66455H4.51594C2.57721 2.66455 1 4.24182 1 6.18049V15.4839C1 17.4226 2.57727 18.9998 4.51594 18.9998H13.8955C15.8342 18.9998 17.4115 17.4226 17.4115 15.4839V8.91366C17.4115 8.48626 17.065 8.13976 16.6376 8.13976Z"
				fill={color}
			/>
		</svg>
	);
};
