import { createDraftSafeSelector, createReducer } from "@reduxjs/toolkit";
import { apiGetListBooking, apiGetBookingDetail, apiUpdateBooking } from "./apis";

const INITIAL_STATE = {
	bookingList: {},
	bookingDetail: {},
};

export const bookingReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(apiGetListBooking.fulfilled, (state, action) => {
		state.bookingList = action.payload;
	});
	builder.addCase(apiGetBookingDetail.fulfilled, (state, action) => {
		state.bookingDetail = action.payload;
	});
	builder
		.addCase(apiUpdateBooking.fulfilled, (state, action) => {
			// state.bookingDetail = action.payload;
		})
		.addDefaultCase(() => {});
});

export const bookingSelector = createDraftSafeSelector(
	(state) => state.bookingReducer,
	(state) => state
);
