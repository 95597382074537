/* eslint-disable react/prop-types */
import React from "react";

export const IconPlusCircleSvg = ({ color = "#595959", ...props }) => {
	return (
		<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M9 1.12842C4.58853 1.12842 1 4.94729 1 9.64193C1 14.3366 4.58853 18.1554 9 18.1554C13.4115 18.1554 17 14.3359 17 9.64193C17 4.94796 13.4115 1.12842 9 1.12842ZM9 16.8365C5.27266 16.8365 2.23934 13.6092 2.23934 9.64193C2.23934 5.67467 5.27266 2.44731 9 2.44731C12.7273 2.44731 15.7607 5.67467 15.7607 9.64193C15.7607 13.6092 12.728 16.8365 9 16.8365Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
			<path
				d="M12.0987 8.92323H9.61998V6.28544C9.61998 5.92142 9.34298 5.62598 9.00029 5.62598C8.6576 5.62598 8.3806 5.92142 8.3806 6.28544V8.92323H5.90191C5.55923 8.92323 5.28223 9.21868 5.28223 9.5827C5.28223 9.94672 5.55923 10.2422 5.90191 10.2422H8.3806V12.88C8.3806 13.244 8.6576 13.5394 9.00029 13.5394C9.34298 13.5394 9.61998 13.244 9.61998 12.88V10.2422H12.0987C12.4414 10.2422 12.7184 9.94672 12.7184 9.5827C12.7184 9.21868 12.4414 8.92323 12.0987 8.92323Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
		</svg>
	);
};
