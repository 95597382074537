/* eslint-disable react/prop-types */
import React from "react";
import { DatePicker as AntDatePicker } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";

import { IconCalendarSvg, IconArrowLeftSvg, IconArrowRightSvg } from "../Svgs";

const DATE_PICKER_FORMAT = "dddd, MMM DD";

export const DatePicker = ({ defaultValue = null, dateFormat = DATE_PICKER_FORMAT, ...props }) => {
	return (
		<StyledDatePicker
			prevIcon={<IconArrowLeftSvg width={9} height={14} color="#9F9F9F" />}
			nextIcon={<IconArrowRightSvg width={9} height={14} color="#9F9F9F" />}
			defaultValue={defaultValue ? dayjs(defaultValue) : dayjs()}
			format={dateFormat}
			superNextIcon={null}
			superPrevIcon={null}
			inputReadOnly
			placeholder="Date picker"
			picker="date"
			mode="date"
			size="large"
			showToday={false}
			allowClear={false}
			suffixIcon={null}
			panelRender={(originPanel) => <PanelWrapper>{originPanel}</PanelWrapper>}
			inputRender={(props) => {
				return (
					<InputRenderWrapper>
						<div style={{ display: "flex" }}>
							<IconCalendarSvg />
						</div>
						<InputRender {...props} />
					</InputRenderWrapper>
				);
			}}
			{...props}
		/>
	);
};

const StyledDatePicker = styled(AntDatePicker)`
	width: 100%;
	border-radius: 12px;
	border: 1px solid #e9e8e8;
	&.ant-picker-large {
		height: 50px;
		padding-left: 16px;
		padding-right: 16px;
	}
`;

const PanelWrapper = styled.div`
	.ant-picker-date-panel {
		width: auto;
	}
	.ant-picker-header {
		padding: 0 24px;
		margin-top: 16px;
		border: 0;
	}
	.ant-picker-date-panel .ant-picker-body {
		padding: 0 24px 16px 24px;
	}
	.ant-picker-header-super-prev-btn,
	.ant-picker-header-super-next-btn {
		display: none;
	}
	.ant-picker-header-prev-btn,
	.ant-picker-header-next-btn {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.ant-picker-header-view {
		font-size: 16px;
		line-height: 24px;
		font-family: DMSans-Medium;
		color: #222222;
		font-weight: 500;
	}
	.ant-picker-content thead th {
		font-size: 12px;
		line-height: 20px;
		color: #d6d6d6;
		font-weight: 700;
		text-transform: uppercase;
	}
	.ant-picker-date-panel .ant-picker-content th {
		width: 40px;
	}
	.ant-picker-cell .ant-picker-cell-inner {
		display: flex;
		min-width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 40px;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: DMSans-Regular;
		margin: 0 auto;
	}
	.ant-picker-cell-in-view .ant-picker-cell-inner {
		color: #222222 !important;
	}
	.ant-picker-cell-today .ant-picker-cell-inner::before {
		border-radius: 40px !important;
	}
	.ant-picker-cell {
		padding: 2px 0;
	}
`;

const InputRenderWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const InputRender = styled.input`
	border: 0;
	outline: 0;
	font-size: 14px;
	line-height: 24px;
	color: #222222;
	font-family: DMSans-Regular;
	margin-left: 12px;
	width: 100%;
	height: 100%;
	padding: 0;
`;
