/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Upload } from "antd";
import { isEmpty, noop } from "lodash";

import { TOAST_MESSAGE_TYPE, UPLOAD_IMAGE_MAX_SIZE, UPLOAD_ONE_MB } from "utils/constants";
import { getBase64, toast } from "utils/helpers";
import { IconCloseSvg, IconUploadSvg } from "../Svgs";
import { LoadingElement } from "../Loading/LoadingElement";

export const SingleUploadSquare = ({
	onChange = noop,
	defaultImgUrl = null,
	maxSize = UPLOAD_IMAGE_MAX_SIZE,
	width = "100%",
	height = 160,
	allowClear = true,
	...uploadProps
}) => {
	const [loading, setLoading] = useState(false);
	const [imgUrl, setImgUrl] = useState(null);

	useEffect(() => {
		if (!isEmpty(defaultImgUrl)) {
			setImgUrl(defaultImgUrl);
		}
	}, [defaultImgUrl]);

	const handleChange = async ({ file, fileList }) => {
		if (file) {
			setLoading(true);
			if (file.size > maxSize) {
				toast({
					type: TOAST_MESSAGE_TYPE.ERROR,
					message: `Hình ảnh phải bé hơn ${UPLOAD_IMAGE_MAX_SIZE / UPLOAD_ONE_MB}MB!`,
				});
			} else {
				onChange(file, fileList);
				const img = await getBase64(file);
				setImgUrl(img);
			}
			setLoading(false);
		}
	};

	const handleClearFile = () => {
		setLoading(true);
		setImgUrl(null);
		onChange(null, null);
		setLoading(false);
	};

	return (
		<Container style={{ width, height }}>
			<UploadContainer allowClear={allowClear}>
				<Upload
					beforeUpload={() => false}
					accept=".jpeg,.png,.jpg"
					showUploadList={false}
					onChange={handleChange}
					{...uploadProps}
				>
					<UploadHolder>
						{imgUrl ? (
							<>
								<UploadImage alt="upload image" src={imgUrl} />
								{!loading && (
									<UploadHolderHidden>
										<IconUploadSvg width="32" height="32" />
									</UploadHolderHidden>
								)}
							</>
						) : (
							<>
								{!loading && (
									<UploadHolder>
										<IconUploadSvg width="32" height="32" />
									</UploadHolder>
								)}
							</>
						)}

						{loading && (
							<LoadingWrapper>
								<LoadingElement />
							</LoadingWrapper>
						)}
					</UploadHolder>
				</Upload>
			</UploadContainer>
			{allowClear && imgUrl && (
				<ClearWrapper onClick={handleClearFile}>
					<IconCloseSvg width={18} height={18} color="#595959" />
				</ClearWrapper>
			)}
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const UploadContainer = styled.div`
	border: 1px dashed #121216;
	border-radius: 8px;
	cursor: pointer;
	width: ${({ allowClear }) => (allowClear ? "calc(100% - 32px)" : "100%")};
	height: ${({ allowClear }) => (allowClear ? "calc(100% - 32px)" : "100%")};
	overflow: hidden;
	.ant-upload {
		width: 100%;
		height: 100%;
	}
`;

const UploadHolder = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: relative;
`;

const UploadHolderHidden = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	&:hover {
		opacity: 1;
	}
`;

const UploadImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const ClearWrapper = styled.div`
	width: 32px;
	height: 32px;
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f0f3f9;
	border-radius: 50%;
	cursor: pointer;
`;

const LoadingWrapper = styled.div`
	position: absolute;
	inset: 0;
`;
