import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { handleApi } from "configs/handleApi";

const actionGetListApartment = createAction("APARTMENT_GET_LIST_APARTMENT");
const actionGetApartmentDetail = createAction("APARTMENT_GET_APARTMENT_DETAIL");

export const apiGetListApartment = createAsyncThunk(
	actionGetListApartment.toString(),
	// @ts-ignore
	async ({ limit, ...params } = {}) => {
		// @ts-ignore
		return handleApi({ url: "/private/apartments", params: { limit: limit || 10, ...params } });
	}
);

export const apiGetApartmentDetail = createAsyncThunk(actionGetApartmentDetail.toString(), async (uid) => {
	return await handleApi({ url: `/private/apartments/${uid}` });
});

export const apiUpdateApartment = async ({ uid, data = {} }) => {
	return await handleApi({ url: `/private/apartments/${uid}`, method: "PUT", data });
};

export const apiCreateNewApartment = async (data = {}) => {
	return await handleApi({ url: "/private/apartments", method: "POST", data });
};

export const apiDeleteApartment = async (uid) => {
	return await handleApi({ url: `/private/apartments/${uid}`, method: "DELETE" });
};

export const apiCreateApartmentPriceRange = async ({ apartmentId, data = {} }) => {
	return await handleApi({ url: `/private/apartments/${apartmentId}/price`, method: "POST", data });
};

export const apiUpdateApartmentPriceRange = async ({ apartmentId, priceId, data = {} }) => {
	return await handleApi({ url: `/private/apartments/${apartmentId}/price/${priceId}`, method: "PUT", data });
};

export const apiDeleteApartmentPriceRange = async ({ apartmentId, priceId }) => {
	return await handleApi({ url: `/private/apartments/${apartmentId}/price/${priceId}`, method: "DELETE" });
};

export const apiUploadApartmentImage = async ({ apartmentId, files, album }) => {
	const formData = new FormData();
	files.forEach((f) => {
		formData.append("file", f?.originFileObj ?? f);
	});
	return await handleApi({
		url: `/private/apartments/${apartmentId}/upload`,
		params: { album },
		data: formData,
		method: "POST",
	});
};

export const apiDeleteApartmentImage = async (fileId) => {
	return await handleApi({ url: `/private/files/${fileId}`, params: { permanent: true }, method: "DELETE" });
};

export const fetchSearchApartments = async (params = {}) => {
	const response = await handleApi({ url: "/private/apartments", params: { limit: 9999, ...params } });
	return response;
};

export const apiUpdateOrderImagesApartment = async ({ apartmentId, data = {} }) => {
	return await handleApi({ url: `/private/apartments/${apartmentId}/images`, method: "PUT", data });
};
