import React from "react";
import styled from "styled-components";

import { Typography } from "components/elements";

export const ErrorElement = () => {
	return (
		<Container>
			<Typography color="#DD4558" variant="h3" bottom={12} align="center">
				SOMETHING WENT WRONG
			</Typography>
			<Typography align="center">We are experiencing some technical difficulties.</Typography>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 128px);
`;
