/* eslint-disable react/prop-types */
import React from "react";

export const IconArrowSvg = ({ color = "#595959", ...props }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M17.7821 8.9792L13.0208 4.21788C12.7303 3.92737 12.2591 3.92737 11.9686 4.21788C11.6781 4.50844 11.6781 4.97945 11.9686 5.27001L15.4599 8.76131H2.74396C2.33311 8.76131 2 9.09443 2 9.50527C2 9.91606 2.33311 10.2492 2.74396 10.2492H15.4599L11.9687 13.7405C11.6782 14.0311 11.6782 14.5021 11.9687 14.7927C12.114 14.9378 12.3044 15.0106 12.4948 15.0106C12.6852 15.0106 12.8756 14.9378 13.0209 14.7927L17.7821 10.0313C18.0726 9.74078 18.0726 9.26976 17.7821 8.9792Z"
				fill={color}
			/>
		</svg>
	);
};
