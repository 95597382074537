import React from "react";
import { Modal as AntModal, Spin } from "antd";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

export const Loading = () => {
	return (
		<StyledModal centered open={true} footer={null} closable={false}>
			<Spin indicator={<LoadingOutlined style={{ fontSize: 150 }} spin />} />
		</StyledModal>
	);
};

const StyledModal = styled(AntModal)`
	.ant-modal-content {
		box-shadow: none;
		border: 0;
		background: transparent;
	}
	.ant-modal-body {
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
