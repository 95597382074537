/* eslint-disable react/prop-types */
import React from "react";

import NoResultsPNG from "assets/images/no-results.png";
import { Typography } from "../Typography";

export const NoResults = ({ description }) => {
	return (
		<div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: 12 }}>
			<img width={72} height={72} src={NoResultsPNG} alt="no results" />
			{description && (
				<Typography variant="body3" top={12}>
					{description}
				</Typography>
			)}
		</div>
	);
};
