import axios from "axios";
import qs from "qs";

import { capitalizeStrings, toast } from "utils/helpers";
import { ERROR_CLIENT_UNKNOWN_ERROR, TOAST_MESSAGE_TYPE, ACCESS_TOKEN } from "utils/constants";

const baseURL = process.env.REACT_APP_API_URL;

export const handleApi = async ({
	url = "/",
	method = "GET",
	data = {},
	params = {},
	responseType = "json",
	contentType = "application/json",
}) => {
	const controller = new AbortController();

	const requestConfig = {
		url,
		method,
		baseURL,
		params,
		data,
		headers: { "Content-Type": contentType },
		paramsSerializer(params) {
			return qs.stringify(params, { arrayFormat: "brackets" });
		},
		responseType,
		maxContentLength: 2000,
		maxBodyLength: 2000,
		validateStatus(status) {
			return status >= 200 && status < 300;
		},
	};

	const instance = axios.create({ signal: controller.signal });

	instance.interceptors.request.use(
		(config) => {
			const access_token = localStorage.getItem(ACCESS_TOKEN);
			if (access_token) {
				config.headers.authorization = `Bearer ${access_token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	return await instance
		.request(requestConfig)
		.then((res) => res.data)
		.catch((error) => {
			const errorMsg = error.response?.data?.error;
			if (errorMsg === "TOKEN_EXPIRED") {
				localStorage.clear();
				window.location.href = "/sign-in";
			}
			const err = errorMsg ? capitalizeStrings(errorMsg) : ERROR_CLIENT_UNKNOWN_ERROR;
			toast({ type: TOAST_MESSAGE_TYPE.ERROR, message: err });
			return { err };
		});
};
