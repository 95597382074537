/* eslint-disable react/prop-types */
import React from "react";
import { TimePicker as AntTimePicker } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";

import { IconClockSvg } from "../Svgs";

const TIME_PICKER_FORMAT = "HH:mm";

export const TimePicker = ({ defaultValue = null, value = dayjs(), ...props }) => {
	return (
		<StyledTimePicker
			showNow={false}
			size="large"
			value={value}
			inputReadOnly
			defaultValue={defaultValue ? dayjs(defaultValue) : dayjs()}
			allowClear={false}
			format={TIME_PICKER_FORMAT}
			suffixIcon={<TimeSuffix>{dayjs(value).format("A")}</TimeSuffix>}
			panelRender={(originPanel) => <PanelWrapper>{originPanel}</PanelWrapper>}
			inputRender={(props) => {
				return (
					<InputRenderWrapper>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<IconClockSvg />
						</div>
						<InputRender {...props} />
					</InputRenderWrapper>
				);
			}}
			{...props}
		/>
	);
};

const StyledTimePicker = styled(AntTimePicker)`
	width: 100%;
	border-radius: 12px;
	border: 1px solid #e9e8e8;
	padding-left: 20px;
	padding-right: 8px;
	height: 44px;
	&.ant-picker-large {
		height: 50px;
	}
	&.ant-picker-small {
		height: 40px;
		border-radius: 8px;
		padding-left: 16px;
		padding-right: 4px;
	}
`;

const PanelWrapper = styled.div`
	.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
		width: 100%;
		height: 36px;
		color: #222222;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-inline-start: 0;
	}
	.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
		background: #265D71;
	}
	.ant-picker-time-panel-column {
		width: 75px;
	}
	.ant-picker-ranges .ant-picker-ok {
		margin-inline-start: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.ant-picker-ranges .ant-picker-ok button {
		height: 36px;
		padding: 0px 26px;
		border-radius: 32px;
		background-color: transparent;
		border: 1px solid #d6d6d6;
		color: #222222;
		font-size: 14px;
		font-family: DMSans-Medium;
	}
	.ant-picker-time-panel-column {
		margin: 0;
	}
	.ant-picker-time-panel-column::after {
		height: 0;
	}
	.ant-picker-time-panel-column::-webkit-scrollbar {
		width: 4px;
	}
	.ant-picker-time-panel-column::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background: #ededed;
	}
	.ant-picker-ranges {
		padding: 8px 12px;
	}
`;

const InputRenderWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const InputRender = styled.input`
	border: 0;
	outline: 0;
	font-size: 14px;
	line-height: 24px;
	color: #222222;
	font-family: DMSans-Regular;
	margin-left: 12px;
	width: 100%;
	height: 100%;
	padding: 0;
`;

const TimeSuffix = styled.div`
	width: 30px;
	height: 30px;
	background: #ededed;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	line-height: 16px;
	color: #333333;
	font-family: DMSans-Medium;
`;
