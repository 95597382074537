/* eslint-disable react/prop-types */
import React from "react";

export const IconDeleteSvg = ({ color = "#BF1A2F", ...props }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.4557 5C16.4557 4.655 16.1757 4.375 15.8307 4.375H4.16406C3.81906 4.375 3.53906 4.655 3.53906 5V16.25C3.53906 16.9683 3.82406 17.6575 4.3324 18.165C4.8399 18.6733 5.52906 18.9583 6.2474 18.9583H13.7474C14.4657 18.9583 15.1549 18.6733 15.6624 18.165C16.1707 17.6575 16.4557 16.9683 16.4557 16.25C16.4557 12.5 16.4557 5 16.4557 5ZM15.2057 5.625V16.25C15.2057 16.6367 15.0524 17.0075 14.7782 17.2808C14.5049 17.555 14.1341 17.7083 13.7474 17.7083C11.6291 17.7083 8.36573 17.7083 6.2474 17.7083C5.86073 17.7083 5.4899 17.555 5.21656 17.2808C4.9424 17.0075 4.78906 16.6367 4.78906 16.25V5.625H15.2057Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.75 5.62533C14.095 5.62533 14.375 5.34533 14.375 5.00033C14.375 5.00033 14.375 4.16699 14.375 3.33366C14.375 2.72616 14.1333 2.14283 13.7042 1.71283C13.2742 1.28366 12.6908 1.04199 12.0833 1.04199C10.8433 1.04199 9.15667 1.04199 7.91667 1.04199C7.30917 1.04199 6.72583 1.28366 6.29583 1.71283C5.86667 2.14283 5.625 2.72616 5.625 3.33366V5.00033C5.625 5.34533 5.905 5.62533 6.25 5.62533H13.75ZM13.125 4.37533H6.875V3.33366C6.875 3.05699 6.985 2.79283 7.18 2.59699C7.37583 2.40199 7.64 2.29199 7.91667 2.29199H12.0833C12.36 2.29199 12.6242 2.40199 12.82 2.59699C13.015 2.79283 13.125 3.05699 13.125 3.33366V4.37533Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.6654 4.375H3.33203C2.98703 4.375 2.70703 4.655 2.70703 5C2.70703 5.345 2.98703 5.625 3.33203 5.625H16.6654C17.0104 5.625 17.2904 5.345 17.2904 5C17.2904 4.655 17.0104 4.375 16.6654 4.375Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.28906 8.75V14.5833C7.28906 14.9283 7.56906 15.2083 7.91406 15.2083C8.25906 15.2083 8.53906 14.9283 8.53906 14.5833V8.75C8.53906 8.405 8.25906 8.125 7.91406 8.125C7.56906 8.125 7.28906 8.405 7.28906 8.75Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.457 8.75V14.5833C11.457 14.9283 11.737 15.2083 12.082 15.2083C12.427 15.2083 12.707 14.9283 12.707 14.5833V8.75C12.707 8.405 12.427 8.125 12.082 8.125C11.737 8.125 11.457 8.405 11.457 8.75Z"
				fill={color}
				stroke={color}
				strokeWidth="0.3"
			/>
		</svg>
	);
};
